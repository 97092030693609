import {
  BETA_URL,
  GAMMA_URL,
  PROD_URL,
  DEV_IRM_URL,
  UAT_IRM_URL,
  PROD_IRM_URL,
  TPS,
  SVDP,
  TPTA_TABLE,
  TPRI_TABLE,
  CONSULT_TABLE,
  DDQ,
  VENDOR_TEAM_MANAGEMENT,
  VENDOR_INVITE_CONTACT,
  VENDOR_PROFILE,
  VENDOR_PROFILE_EDIT,
} from '../constants/urlConstants';

export const isBeta = (): boolean => window.location.origin === BETA_URL;
export const isGamma = (): boolean => window.location.origin === GAMMA_URL;
export const isProd = (): boolean => window.location.origin === PROD_URL;

export function getTPSPortalUrl(query: string, pathName: string): string {
  let redirectUrl = DEV_IRM_URL;

  if (isGamma()) {
    redirectUrl = UAT_IRM_URL;
  } else if (isProd()) {
    redirectUrl = PROD_IRM_URL;
  }

  const sysId = query.slice(1);
  let queryConstructor = null;
  let portal = SVDP;

  if (/TPTA/.test(pathName)) {
    queryConstructor = TPTA_TABLE;
    portal = TPS;
  }

  if (/TPRI/.test(pathName)) {
    queryConstructor = TPRI_TABLE;
    portal = TPS;
  }

  if (/Consult/.test(pathName)) {
    queryConstructor = CONSULT_TABLE;
    portal = TPS;
  }

  if (/VendorInviteContact/.test(pathName)) queryConstructor = VENDOR_INVITE_CONTACT;

  if (/VendorProfileEdit/.test(pathName)) queryConstructor = VENDOR_PROFILE_EDIT;

  if (/DDQ/.test(pathName)) queryConstructor = DDQ;

  if (/VendorTeamManagement/.test(pathName)) queryConstructor = VENDOR_TEAM_MANAGEMENT;

  if (/VendorProfile/.test(pathName)) queryConstructor = VENDOR_PROFILE;

  //if there is a match this loop one will be used to construct a query parameter string
  if (queryConstructor) {
    redirectUrl += `/${portal}?`;
    for (const [key, value] of queryConstructor) {
      redirectUrl += `${key}=${value}&`;
    }
  }

  //for records there is an sys_id attached
  if (sysId && /sys_id/.test(sysId)) {
    redirectUrl += sysId;
  }

  return redirectUrl;
}
