import React from 'react';
import useAuth from '../../hooks/useAuth';
import FederatedSignIn from '../../components/Login/FederatedSignIn';

export default function SurveysPage() {
  const { user } = useAuth();

  return user ? (
    <div>
      <p>Temp Surveys Page</p>
    </div>
  ) : (
    <FederatedSignIn />
  );
}
