import React from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';

import MuiBox from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import MuiTypography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import { PRIMARY_BLUE } from '../../constants/constants';
import { START_INTAKE } from '../../constants/linkConstants';

function NewIntakeCard({ bundle }: WithBundleProps) {
  const learnMoreStyle = {
    color: PRIMARY_BLUE,
  };

  return (
    <MuiCard variant="elevation">
      <MuiCardContent style={{ paddingBottom: '5px' }}>
        <MuiBox className="new-intake-card-header-container">
          <MuiTypography style={{ fontSize: '1.2rem' }} variant="h4">
            <MuiLink style={{ color: PRIMARY_BLUE }} {...{ component: Link, to: START_INTAKE.href }} underline="none">
              {bundle.getMessage('card_header')}
            </MuiLink>
          </MuiTypography>
          <MuiTypography>
            <MuiLink style={learnMoreStyle} {...{ component: Link, to: START_INTAKE.href }} underline="none">
              {bundle.getMessage('learn_more')}
            </MuiLink>
          </MuiTypography>
        </MuiBox>
      </MuiCardContent>
    </MuiCard>
  );
}

export default withBundle('components.NewIntakeCard')(NewIntakeCard);
