import React from 'react';
import useAuth from '../../hooks/useAuth';
import FederatedSignIn from '../../components/Login/FederatedSignIn';

export default function TempDdq() {
  const { user } = useAuth();
  return user ? (
    <div>
      <p>Temp Ddq Page</p>
    </div>
  ) : (
    <FederatedSignIn />
  );
}
