import React from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { MessageBundle } from '@amzn/arb-tools';

import MuiBox from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import MuiCardActions from '@mui/material/CardActions';
import MuiCardContent from '@mui/material/CardContent';
import MuiTypography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import MuiDivider from '@mui/material/Divider';
import MuiCollapse from '@mui/material/Collapse';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';
import MuiExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import Button from '../Button/Button';
import FavoritesStar from '../FavoritesStar/FavoritesStar';
import { DownloadSvgIcon } from './DownloadIconSvg';
import Assessment, { State, AssessmentOutcomeStatus, ProjectState } from '../../data/RecordMetadata';
import { AMAZON_EMBER, OFF_BLACK, GRAY, RED, WHITE, MINT_GREEN, YELLOW, PRIMARY_BLUE } from '../../constants/constants';
import './ThirdPartyTieringAssessmentCard.css';
import { Activity } from '../../data/RecordMetadata';

/****** Styled Components Section *****/

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props; // eslint-disable-line
  return <MuiIconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(360deg)' : 'rotate(180deg)',
  padding: '0',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type StatusIndicatorProps = {
  status: AssessmentOutcomeStatus;
};

const StatusIndicator = styled(MuiBox)<StatusIndicatorProps>(({ theme, status }) => ({
  position: 'absolute',
  top: '0',
  right: '0',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  padding: '4px',
  writingMode: 'vertical-lr',
  textAlign: 'center',
  fontSize: theme.typography.pxToRem(10),
  fontWeight: 'bold',
  transform: 'rotate(180deg)',
  backgroundColor: getAssessmentOutcomeLabel(status),
  color: WHITE,
  fontFamily: AMAZON_EMBER,
  whiteSpace: 'nowrap',
}));

const SmallFontType = styled(MuiTypography)({
  fontSize: '0.8rem',
});

const LargeFontType = styled(MuiTypography)({
  fontSize: '1.2rem',
});

const LabelContainer = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const AccordionArrowContainer = styled(MuiCardActions)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '0',
});

/****** Helper Functions Section *****/

function getAssessmentOutcomeLabel(status: AssessmentOutcomeStatus) {
  switch (status) {
    case AssessmentOutcomeStatus.NOT_MET:
      return RED;
    case AssessmentOutcomeStatus.MET:
      return MINT_GREEN;
    case AssessmentOutcomeStatus.EXCEPTIONS:
      return YELLOW;
  }
}

function getQuestionnaireText(type: ProjectState, bundle: MessageBundle) {
  switch (type) {
    case ProjectState.INTAKE:
      return (
        <MuiBox className="text-icon">
          <SmallFontType>{bundle.getMessage('intake_questionnaire_text')}</SmallFontType>
        </MuiBox>
      );
    case ProjectState.DDQ:
      return (
        <MuiBox className="text-icon">
          <SmallFontType>{bundle.getMessage('ddq_text')}</SmallFontType>
          <DownloadSvgIcon />
        </MuiBox>
      );
    case ProjectState.ISSUE:
      return (
        <MuiBox className="text-icon">
          <Button
            // eslint-disable-next-line
            onClick={() => {}}
            type="dark"
            style={{ fontSize: '10px', marginTop: '5px' }}
          >
            {bundle.formatMessage('issue_card_button', { numIssues: 2 })}
          </Button>
        </MuiBox>
      );
  }
}

function cardLabel(labelType: State, bundle: MessageBundle) {
  let labelClass = '';
  switch (labelType) {
    case State.AWAITING_ASSESSOR:
      labelClass = 'assessor';
      break;
    case State.AWAITING_REQUESTER:
      labelClass = 'requestor';
      break;
    case State.AWAITING_VENDOR:
      labelClass = 'vendor';
      break;
    case State.CLOSED:
      labelClass = 'closed';
      break;
  }
  return (
    <MuiBox className={`${labelClass}-label card-label`}>
      <MuiTypography variant="inherit">{bundle.getMessage(labelType)}</MuiTypography>
    </MuiBox>
  );
}

function getAccordionText(project: Assessment, bundle: MessageBundle) {
  switch (project.details.member.tieringAssessment.state) {
    case ProjectState.INTAKE:
      return (
        <SmallFontType>
          {bundle.formatMessage('accordion_text_intake', {
            expirationDate: project.details.member.intakeAssessmentSchedule.expectedOnSiteDate,
            // eslint-disable-next-line
            span: (child: any) => (
              <span key="1" style={{ color: RED }}>
                {child}
              </span>
            ),
          })}
        </SmallFontType>
      );

    case ProjectState.DDQ:
      return <SmallFontType>{bundle.formatMessage('accordion_text', { percentComplete: 60 })}</SmallFontType>;
    default:
      return;
  }
}

function determineTimeDiff(timestamp: Date) {
  const timeSince = Math.abs(Date.now() - timestamp.getTime());
  let minutes = Math.floor(timeSince / (1000 * 60));
  minutes = minutes === 0 ? 1 : minutes; // So we never display 0m ago.
  const hours = Math.floor((timeSince / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeSince / (1000 * 60 * 60 * 24));

  // Return the proper parameters for the PUFF-J function
  if (minutes < 60) return { timeUnit: 'min', timeDiff: minutes };
  if (days == 0) return { timeUnit: 'hours', timeDiff: hours };

  return { timeUnit: 'day', timeDiff: timestamp };
}

/****** Component ******/
interface ThirdPartyTieringAssessmentCardProps extends WithBundleProps {
  project: Assessment;
}

function ThirdPartyTieringAssessmentCard({ bundle, ...props }: ThirdPartyTieringAssessmentCardProps) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Need extra padding on the issue cards
  const cardClassName =
    props.project.details.member.tieringAssessment.state === ProjectState.ISSUE ? 'intake-tpta-card' : '';

  // TODO this will link to the project ID in the future
  const projectLink = `/${props.project.tptaNumber}?sys_id=${props.project.tpsRecordId}`;

  return (
    <MuiCard variant="elevation" className={`tpta-card ${cardClassName}`}>
      <MuiCardContent style={{ paddingBottom: '5px' }}>
        <MuiTypography>
          <MuiLink
            style={{ color: PRIMARY_BLUE }}
            {...{ component: Link, to: projectLink }}
            underline="none"
            className="view-link"
          >
            {bundle.getMessage('view_button')}
          </MuiLink>
        </MuiTypography>
        <MuiBox className="card-links-container">
          <MuiBox className="text-icon">
            <FavoritesStar project={props.project} />
            <LargeFontType sx={{ paddingLeft: '5px' }} variant="h4" className="project-name-link">
              <MuiLink {...{ component: Link, to: projectLink }} underline="none" color="inherit">
                {props.project.vendorName}
              </MuiLink>
            </LargeFontType>
          </MuiBox>
          <MuiBox className="vendor-tpta-label-container">
            <LabelContainer className="vendor-tpta-container">
              <MuiBox className="vendor-name-link">
                <MuiLink {...{ component: Link, to: projectLink }} underline="none" color="inherit">
                  <SmallFontType className="questionnaire-name">
                    {bundle.formatMessage('vendor_tier', {
                      tptaNum: props.project.tptaNumber,
                      tier: props.project.details.member.tieringAssessment.thirdpartyTier,
                    })}
                  </SmallFontType>
                </MuiLink>
              </MuiBox>
              {cardLabel(props.project.state, bundle)}
            </LabelContainer>
          </MuiBox>
        </MuiBox>
        <MuiBox>
          <LabelContainer className="label-containers">
            <SmallFontType sx={{ color: OFF_BLACK }}>{props.project.projectName}</SmallFontType>
          </LabelContainer>
        </MuiBox>
        <MuiBox className="tpta-accordion-text">
          {getQuestionnaireText(props.project.details.member.tieringAssessment.state, bundle)}
          {getAccordionText(props.project, bundle)}
        </MuiBox>
        {props.project.details.member.tieringAssessment.state === ProjectState.DDQ &&
          props.project.details.member.tieringAssessment.hasSecurityFollowUp && (
            <MuiBox>
              <LabelContainer sx={{ paddingBottom: '6px' }} className="label-containers">
                <SmallFontType sx={{ color: OFF_BLACK }}>{bundle.getMessage('security_questionnaire')}</SmallFontType>
                {cardLabel(props.project.state, bundle)}
              </LabelContainer>
            </MuiBox>
          )}
        <AccordionArrowContainer disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            className="tpta-card-expand-more-icon"
            aria-expanded={expanded}
            aria-label="show more"
          >
            <MuiExpandMoreIcon />
          </ExpandMore>
        </AccordionArrowContainer>
        <MuiCollapse in={expanded} timeout="auto" unmountOnExit className="tpta-card-activities-collapse-container">
          <MuiDivider variant="fullWidth" style={{ margin: '2px 0 8px' }} />
          <SmallFontType style={{ margin: '8px' }}>
            {props.project.activities
              ? bundle.formatMessage('card_activites', { numActivites: props.project.activities.length })
              : bundle.formatMessage('card_activites', { numActivites: 0 })}
          </SmallFontType>
          {props.project.activities && props.project.activities.length != 0 ? (
            props.project.activities.map((activity: Activity) => {
              return (
                <MuiBox key={activity.id} className="recent-activities-container">
                  <SmallFontType className="recent-activities">
                    {bundle.formatMessage('card_activity', {
                      entity: activity.entity,
                      action: activity.action,
                      payload: activity.payload,
                      b: (child: string) => <b key="1">{child}</b>,
                      // eslint-disable-next-line
                      span: (child: any) => (
                        <span key="2" style={{ color: GRAY }}>
                          {child}
                        </span>
                      ),
                    })}
                  </SmallFontType>
                  <SmallFontType style={{ width: '75px', paddingLeft: '10px', textAlign: 'end' }}>
                    {bundle.formatMessage('activity_timestamp', determineTimeDiff(activity.timestamp))}
                  </SmallFontType>
                </MuiBox>
              );
            })
          ) : (
            <MuiBox className="recent-activities-container"></MuiBox>
          )}
        </MuiCollapse>
      </MuiCardContent>
      {props.project.details.member.tieringAssessment.assessmentOutcome &&
      props.project.details.member.tieringAssessment.state === ProjectState.ISSUE ? (
        <StatusIndicator
          className="assessment-outcome-indicator"
          status={props.project.details.member.tieringAssessment.assessmentOutcome}
        >
          {bundle.getMessage(props.project.details.member.tieringAssessment.assessmentOutcome)}
        </StatusIndicator>
      ) : null}
    </MuiCard>
  );
}

export default withBundle('components.ThirdPartyTieringAssessmentCard')(ThirdPartyTieringAssessmentCard);
