export interface Assessment {
  assessmentId: string;
  projectName: string;
  vendorName: string;
  tptaNumber: string;
  state: State;
  details: ProjectDetailsList;
  isFavorited: boolean;
  severity: Priority;
  activities: Activity[];
  tpsRecordId: string;
}

export interface ProjectDetailsList {
  member: ProjectDetail;
}

export interface ProjectDetail {
  tieringAssessment: TieringAssessment;
  ownership: Ownership;
  intakeAssessmentSchedule: IntakeAssessmentSchedule;
  tieringMetrics: TieringMetrics;
  followupAssessmentNeededOptions: FollowupAssessmentNeededOptions;
}

export interface TieringAssessment {
  assessmentOutcome: AssessmentOutcomeStatus;
  number: string;
  name: string;
  state: ProjectState;
  thirdparty: string;
  thirdpartyTier: Tier;
  deepDiveAssessment: string;
  productOrService: string;
  businessPurpose: string;
  terminated: Checkbox;
  hasSecurityFollowUp: boolean; // possibly temporary
}

export interface Ownership {
  requesterSTeam: User;
  department: string;
  requester: User;
  primaryOwner: User;
  secondaryOwner: User;
  thirdpartyContact: string;
  assignedTo: User;
  seniorLeader: User;
  federatedSecTeam: string;
  parentTpta: string;
  awsDataInvolved: Checkbox;
  abandoned: Checkbox;
  watchList: string;
}

export interface IntakeAssessmentSchedule {
  actualStartDate: Date;
  actualEndDate: Date;
  expectedOnSiteDate: Date;
}

export interface TieringMetrics {
  dataType: string;
  dataClassification: string;
  dataSharingMechanism: string;
  countriesInvolved: Countries[]; //array of countries involved so changed this one from string to an array
  volumeOfRecords: number;
  directGovtSharing: boolean;
  contingentWorkers: boolean;
  secretThirdParty: boolean;
  contractualValue: number;
  contractCentralMatterNumber: number;
  bindleInfo: string;
  awsAccountId: string;
  projectGoLiveDate: Date;
  projectEndDate: Date;
  workDocsConduitAccId: string;
}

export interface FollowupAssessmentNeededOptions {
  saas: Checkbox;
  giftCard: Checkbox;
  s3: Checkbox;
  payment: Checkbox;
}

export enum ActivityAction {
  RECORD_STATE_CHANGE = 'record_state_change',
  INTAKE_COMPLETED = 'intake_completed',
  SECURITY_FOLLOW_UP_COMPLETE = 'security_follow_up_complete',
  COMMENTS_IN_TPTA = 'comments_in_tpta',
  COMMENTS_IN_DDQ = 'comments_in_ddq',
  COMMENTS_IN_TPRI = 'comments_in_tpri',
  DDQ_COMPLETION_CHANGE = 'ddq_completion_change',
  EMAIL_SENT = 'email_sent',
  TPTA_RESULT_CHANGE = 'tpta_result_change',
  DDQ_INHERITED_BY_TPTA = 'ddq_inherited_by_tpta',
  TPTA_INHERITED_DDQ = 'tpta_inherited_ddq',
  CONTACT_CHANGED = 'contact_changed',
  USER_ADDED_TO_WATCHLIST = 'user_added_to_watchlist',
}

export interface Activity {
  id: string;
  entity: string;
  action: string;
  payload: string;
  timestamp: Date;
}

//UpdateItemProps
export interface UpdateItemProps {
  id: string;
  entity: string;
  payload: string;
  timestamp: Date;
  isSystemNotification: boolean;
}

export interface User {
  name: string;
  emailAddress: string;
  userId: string;
}

export enum State {
  AWAITING_ASSESSOR = 'awaiting_assessor',
  AWAITING_REQUESTER = 'awaiting_requestor',
  AWAITING_VENDOR = 'awaiting_vendor',
  CLOSED = 'tpta_closed',
}

export enum Countries {
  US = 'US',
  INDIA = 'India',
  AUSTRALIA = 'Australia',
  AUSTRIA = 'Austria',
  BRAZIL = 'Brazil',
  CANADA = 'Canada',
  CHINA = 'China',
  CZ = 'Czech Republic',
  EGYPT = 'Egypt',
  FRANCE = 'France',
  GERMANY = 'Germany',
  ITALY = 'Italy',
  JAPAN = 'Japan',
}

export enum Checkbox {
  CHECKED = 'Checked',
  UNCHECKED = 'Unchecked',
}

//ProjectClosedStatus
export enum AssessmentOutcomeStatus {
  NOT_MET = 'bar_not_met',
  MET = 'bar_met',
  EXCEPTIONS = 'bar_met_with_exceptions',
}

//ProjectTier
export enum Tier {
  ONE = 'tier_1',
  TWO = 'tier_2',
  THREE = 'tier_3',
  FOUR = 'tier_4',
}

//ProjectSeverity
export enum Priority {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

//below are missing in backend data model just added for reference
export enum ProjectState {
  INTAKE = 'intake',
  DDQ = 'ddq',
  ISSUE = 'issue',
}

export interface Issues {
  id: string;
  entity: string;
  action: string;
  payload: string;
  timestamp: Date;
}

type ConsultStatus = 'open' | 'closed';
export interface TempConsults {
  id: string;
  consultName: string;
  consultDescription: string;
  tprtNum: string;
  dateCreated: Date;
  state: State;
  status: ConsultStatus;
}

export enum TakeActionItemType {
  ASSESSMENT = 'Assessments',
  ISSUES = 'Issues & Findings',
  CONSULTS = 'Consults',
}

export interface TempCompleteAssessments {
  id: string;
  projectName: string;
  vendorName: string;
  tier: Tier;
  tptaNumber: string;
  dateOpened: Date;
  dateClosed: Date;
  result: string;
}

export default Assessment;
