import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiLink from '@mui/material/Link';
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material';
import { TempAssessmentPayload } from '../../data/TempRecordMetadata';
import { COMPLETE_ASSESSMENTS_PAGE } from '../../constants/linkConstants';
import { Assessment } from '../../data/RecordMetadata';
import './CompleteAssessments.css';
import FilterBar from '../../components/FilterBar/FilterBar';
import filterReducer from '../../components/FilterBar/FilterReducer';
import useAuth from '../../hooks/useAuth';
import FederatedSignIn from '../../components/Login/FederatedSignIn';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const StyledLink = styled(MuiLink)({
  width: '100%',
  height: '60px',
  textDecoration: 'none',
  color: 'black',
});

/**
 * Creating the table for the archive page with clickable links to the
 * archives themselves.
 *
 * Note: Had to use divs and aria roles because the classes <table> element
 * doesn't support making an entire row clickable.
 * @returns JSX
 */

function CompleteAssessments({ bundle }: WithBundleProps) {
  const [state, dispatch] = useReducer(filterReducer, { componentData: [...TempAssessmentPayload] });
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth();
  //memoizing this state properties to avoid unnecessary renders of Filter Child
  const memoizedFilterFields = useMemo(() => state.filterFields, [state.filterFields]);
  const memoizedLabels = useMemo(() => state.labels, [state.labels]);
  const memoizedDispatchFn = useMemo(() => dispatch, [dispatch]);

  //useEffect for initial state to contain filtermap, label sets at component level
  useEffect(() => {
    dispatch({
      type: 'initialSetup',
      payload: {
        labels: ['vendor', 'tier'],
        bundle,
      },
    });
    setLoading(() => false);
  }, []);

  return user ? (
    <div className="complete-assessment-page-container">
      {loading ? (
        <MuiCircularProgress />
      ) : (
        <React.Fragment>
          <FilterBar
            filterFields={memoizedFilterFields}
            filterLabels={memoizedLabels}
            dispatch={memoizedDispatchFn}
            title={'complete_assessments'}
          />
          <div role="table" className="complete-assessment-page-table" style={{ marginTop: '30px' }}>
            <div role="row" className="complete-assessment-page-table-row complete-assessment-page-table-row-header">
              <div role="columnheader">
                <p>{bundle.getMessage('tpta')}</p>
              </div>
              <div role="columnheader">
                <p>{bundle.getMessage('vendor_name')}</p>
              </div>
              <div role="columnheader">
                <p>{bundle.getMessage('project_name')}</p>
              </div>
              <div role="columnheader">
                <p>{bundle.getMessage('tier')}</p>
              </div>
              <div role="columnheader">
                <p>{bundle.getMessage('date_opened')}</p>
              </div>
              <div role="columnheader">
                <p>{bundle.getMessage('date_closed')}</p>
              </div>
              <div role="columnheader">
                <p>{bundle.getMessage('result')}</p>
              </div>
            </div>
            {state.componentData.map((completeAssessments: Assessment, index: number) => {
              // TODO: Add in anything that can be hardcoded to the PUFF-J file (tier, severity, etc.)
              return state.displayData[index] ? (
                <React.Fragment>
                  <StyledLink
                    {...{ component: Link, to: `${COMPLETE_ASSESSMENTS_PAGE.href}/${completeAssessments.tptaNumber}` }}
                    key={completeAssessments.tptaNumber}
                    role="row"
                    className="complete-assessment-page-table-row"
                  >
                    <div role="cell">
                      <p>{completeAssessments.tptaNumber}</p>
                    </div>
                    <div role="cell">
                      <p>{completeAssessments.vendorName}</p>
                    </div>
                    <div role="cell">
                      <p>{completeAssessments.projectName}</p>
                    </div>
                    <div role="cell">
                      <p>{bundle.getMessage(completeAssessments.details.member.tieringAssessment.thirdpartyTier)}</p>
                    </div>
                    <div role="cell">
                      <p>
                        {bundle.formatMessage('date_opened_cell', {
                          dateOpened: completeAssessments.details.member.intakeAssessmentSchedule.actualStartDate,
                        })}
                      </p>
                    </div>
                    <div role="cell">
                      <p>
                        {bundle.formatMessage('date_closed_cell', {
                          dateClosed: completeAssessments.details.member.intakeAssessmentSchedule.actualEndDate,
                        })}
                      </p>
                    </div>
                    <div role="cell">
                      <p>{bundle.getMessage(completeAssessments.details.member.tieringAssessment.assessmentOutcome)}</p>
                    </div>
                  </StyledLink>
                </React.Fragment>
              ) : null;
            })}
          </div>
        </React.Fragment>
      )}
    </div>
  ) : (
    <FederatedSignIn />
  );
}

export default withBundle('pages.CompleteAssessmentsPage')(CompleteAssessments);
