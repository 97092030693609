import {
  Activity,
  ProjectState,
  State,
  AssessmentOutcomeStatus,
  Priority,
  Tier,
  Issues,
  TempConsults,
  TakeActionItemType,
  Assessment,
  Checkbox,
  Countries,
  UpdateItemProps,
} from './RecordMetadata';

// This is mimicing a sorted list from our algorithm.
// TODO: Replace this once the sorting algorithm is created.
//    this can be used as a building block
interface TempTodoListSorted {
  id: string;
  message: string;
  vendorName: string;
  tptaNum: string;
  tier: Tier;
}

// export const TempSortedList: TempTodoListSorted[] = [];
export const TempSortedList: TempTodoListSorted[] = [
  {
    id: '239874h234hjlk3',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.ONE,
  },
  {
    id: '2093842j3j',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.TWO,
  },
  {
    id: '2309234092j3',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.THREE,
  },
  {
    id: '23j42lk3j40g',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.FOUR,
  },
  {
    id: '23987gfd4h234hjlk3',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.ONE,
  },
  {
    id: '2093842werj3j',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.TWO,
  },
  {
    id: '23092340htr92j3',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.THREE,
  },
  {
    id: '23j42lk32323j40g',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.FOUR,
  },
  {
    id: '239874h4322234hjlk3',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.ONE,
  },
  {
    id: '2093842jgrew3j',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.TWO,
  },
  {
    id: '230923werw4092j3',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.THREE,
  },
  {
    id: '23j42lhtrwk3j40g',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.FOUR,
  },
  {
    id: 'wsdfg3343',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.ONE,
  },
  {
    id: '2093842jgwegwwrew3j',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.TWO,
  },
  {
    id: '230923werw42234t3092j3',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.THREE,
  },
  {
    id: '23j4grfewehtrwk3j40g',
    message: 'High Priority item for you to see here.',
    vendorName: 'Vendor1',
    tptaNum: 'TPTA209384',
    tier: Tier.FOUR,
  },
];

export const TempProjectIssues: Issues[] = [
  {
    id: 'kj2kj341902',
    entity: 'DDQ State',
    action: 'changed to',
    payload: 'Awaiting Requester',
    timestamp: new Date('2023-03-28'),
  },
  {
    id: '12lo2ipfji23',
    entity: 'DDQ State',
    action: 'changed to',
    payload: 'Awaiting Requester',
    timestamp: new Date('2023-03-28'),
  },
];

export const TempProjectActivities: Activity[] = [
  {
    id: '112jkh341',
    entity: 'Steve',
    action: 'left a comment',
    payload: 'Great job you got through it quickly!',
    timestamp: new Date('2023-06-09'),
  },
  {
    id: '112jk1lkjj1',
    entity: 'DDQ State',
    action: 'changed to',
    payload: 'Awaiting Requester',
    timestamp: new Date('2023-03-28'),
  },
  {
    id: 'kj2kj341902',
    entity: 'DDQ State',
    action: 'changed to',
    payload: 'Awaiting Requester',
    timestamp: new Date('2023-03-28'),
  },
  {
    id: '12lo2ipfji23',
    entity: 'DDQ State',
    action: 'changed to',
    payload: 'Awaiting Requester',
    timestamp: new Date('2023-03-28'),
  },
];

export const TempAssessmentPayload: Assessment[] = [
  {
    assessmentId: 'ASS53447569',
    projectName: 'TempProject1',
    vendorName: 'TempVendor1',
    tptaNumber: 'TPTA0028239',
    state: State.AWAITING_ASSESSOR,
    severity: Priority.HIGH,
    details: {
      member: {
        tieringAssessment: {
          assessmentOutcome: AssessmentOutcomeStatus.NOT_MET,
          number: 'TA1894829343',
          name: 'Assement1',
          state: ProjectState.INTAKE,
          thirdparty: 'TempThirdParty1',
          thirdpartyTier: Tier.ONE,
          deepDiveAssessment: 'TempDeepAss1',
          productOrService: 'TempProdSer1',
          businessPurpose: 'TempBussPur1',
          terminated: Checkbox.CHECKED,
          hasSecurityFollowUp: true,
        },
        ownership: {
          requesterSTeam: {
            name: 'TempReqTeam1',
            emailAddress: 'TempTeamEmail1@domain.com',
            userId: 'TempUserId1',
          },
          department: 'TempDep1',
          requester: {
            name: 'TempRequester1',
            emailAddress: 'TempRequesterEmail1@domain.com',
            userId: 'TempRequesterId1',
          },
          primaryOwner: {
            name: 'TempPrimOwner1',
            emailAddress: 'TempPrimOwner1@domain.com',
            userId: 'TempPrimaryOwnerId1',
          },
          secondaryOwner: {
            name: 'TempSecondaryOwner1',
            emailAddress: 'TempSecondaryOwner1@domain.com',
            userId: 'TempSecondaryOwnerId1',
          },
          thirdpartyContact: 'TempThirdpartyContact1@domain.com',
          assignedTo: {
            name: 'TempAssignee1',
            emailAddress: 'TempAssignee1@domain.com',
            userId: 'TempAssigneeId1',
          },
          seniorLeader: {
            name: 'TempSeniorLead1',
            emailAddress: 'TempSeniorLead1@domain.com',
            userId: 'TempSeniorLeadId1',
          },
          federatedSecTeam: 'TempFederatedSecTeam1',
          parentTpta: 'TPTA19023321',
          awsDataInvolved: Checkbox.CHECKED,
          abandoned: Checkbox.UNCHECKED,
          watchList: 'TempWatchList1',
        },
        intakeAssessmentSchedule: {
          actualStartDate: new Date(),
          actualEndDate: new Date('2023-04-04'),
          expectedOnSiteDate: new Date(),
        },
        tieringMetrics: {
          dataType: 'TempDataType1',
          dataClassification: 'TempClassification1',
          dataSharingMechanism: 'TempDataSharingMechanism1',
          countriesInvolved: [Countries.US, Countries.INDIA, Countries.AUSTRALIA, Countries.BRAZIL],
          volumeOfRecords: 20_000,
          directGovtSharing: true,
          contingentWorkers: true,
          secretThirdParty: true,
          contractualValue: 10_00_000,
          contractCentralMatterNumber: 1_23_00_000,
          bindleInfo: 'TempBindleInfo1',
          awsAccountId: 'AWS12345678',
          projectGoLiveDate: new Date('2023-03-28'),
          projectEndDate: new Date('2023-03-28'),
          workDocsConduitAccId: 'CDT45238901',
        },
        followupAssessmentNeededOptions: {
          saas: Checkbox.CHECKED,
          giftCard: Checkbox.UNCHECKED,
          s3: Checkbox.CHECKED,
          payment: Checkbox.UNCHECKED,
        },
      },
    },
    isFavorited: true,
    activities: TempProjectActivities,
    tpsRecordId: '190ff57d68419d501fab23856d527405',
  },
  {
    assessmentId: 'ASS53447730',
    projectName: 'TempProject2',
    vendorName: 'TempVendor2',
    tptaNumber: 'TPTA0025355',
    state: State.AWAITING_REQUESTER,
    severity: Priority.LOW,
    details: {
      member: {
        tieringAssessment: {
          assessmentOutcome: AssessmentOutcomeStatus.EXCEPTIONS,
          number: 'TA1894889547',
          name: 'Assement2',
          state: ProjectState.DDQ,
          thirdparty: 'TempThirdParty2',
          thirdpartyTier: Tier.TWO,
          deepDiveAssessment: 'TempDeepAss2',
          productOrService: 'TempProdSer2',
          businessPurpose: 'TempBussPur2',
          terminated: Checkbox.UNCHECKED,
          hasSecurityFollowUp: true,
        },
        ownership: {
          requesterSTeam: {
            name: 'TempReqTeam2',
            emailAddress: 'TempTeamEmail2@domain.com',
            userId: 'TempUserId2',
          },
          department: 'TempDep2',
          requester: {
            name: 'TempRequester2',
            emailAddress: 'TempRequesterEmail2@domain.com',
            userId: 'TempRequesterId2',
          },
          primaryOwner: {
            name: 'TempPrimOwner2',
            emailAddress: 'TempPrimOwner2@domain.com',
            userId: 'TempPrimaryOwnerId2',
          },
          secondaryOwner: {
            name: 'TempSecondaryOwner3',
            emailAddress: 'TempSecondaryOwner3@domain.com',
            userId: 'TempSecondaryOwnerId3',
          },
          thirdpartyContact: 'TempThirdpartyContact2@domain.com',
          assignedTo: {
            name: 'TempAssignee2',
            emailAddress: 'TempAssignee2@domain.com',
            userId: 'TempAssigneeId2',
          },
          seniorLeader: {
            name: 'TempSeniorLead2',
            emailAddress: 'TempSeniorLead2@domain.com',
            userId: 'TempSeniorLeadId2',
          },
          federatedSecTeam: 'TempFederatedSecTeam2',
          parentTpta: 'TPTA19025532',
          awsDataInvolved: Checkbox.UNCHECKED,
          abandoned: Checkbox.CHECKED,
          watchList: 'TempWatchList2',
        },
        intakeAssessmentSchedule: {
          actualStartDate: new Date(),
          actualEndDate: new Date('2023-04-04'),
          expectedOnSiteDate: new Date(),
        },
        tieringMetrics: {
          dataType: 'TempDataType2',
          dataClassification: 'TempClassification2',
          dataSharingMechanism: 'TempDataSharingMechanism2',
          countriesInvolved: [Countries.US, Countries.CANADA, Countries.FRANCE, Countries.ITALY, Countries.JAPAN],
          volumeOfRecords: 30_000,
          directGovtSharing: true,
          contingentWorkers: true,
          secretThirdParty: true,
          contractualValue: 30_00_000,
          contractCentralMatterNumber: 1_55_00_000,
          bindleInfo: 'TempBindleInfo2',
          awsAccountId: 'AWS12346579',
          projectGoLiveDate: new Date('2023-03-28'),
          projectEndDate: new Date('2023-06-28'),
          workDocsConduitAccId: 'CDT45237812',
        },
        followupAssessmentNeededOptions: {
          saas: Checkbox.UNCHECKED,
          giftCard: Checkbox.UNCHECKED,
          s3: Checkbox.UNCHECKED,
          payment: Checkbox.CHECKED,
        },
      },
    },
    isFavorited: false,
    activities: TempProjectActivities,
    tpsRecordId: 'dfa0b3b384532110224f63b44b31bc48',
  },
  {
    assessmentId: 'ASS53445479',
    projectName: 'TempProject3',
    vendorName: 'TempVendor3',
    tptaNumber: 'TPTA0025354',
    state: State.AWAITING_REQUESTER,
    severity: Priority.MEDIUM,
    details: {
      member: {
        tieringAssessment: {
          assessmentOutcome: AssessmentOutcomeStatus.EXCEPTIONS,
          number: 'TA1894999658',
          name: 'Assement3',
          state: ProjectState.DDQ,
          thirdparty: 'TempThirdParty3',
          thirdpartyTier: Tier.ONE,
          deepDiveAssessment: 'TempDeepAss3',
          productOrService: 'TempProdSer3',
          businessPurpose: 'TempBussPur3',
          terminated: Checkbox.CHECKED,
          hasSecurityFollowUp: false,
        },
        ownership: {
          requesterSTeam: {
            name: 'TempReqTeam3',
            emailAddress: 'TempTeamEmail3@domain.com',
            userId: 'TempUserId3',
          },
          department: 'TempDep3',
          requester: {
            name: 'TempRequester3',
            emailAddress: 'TempRequesterEmail3@domain.com',
            userId: 'TempRequesterId3',
          },
          primaryOwner: {
            name: 'TempPrimOwner3',
            emailAddress: 'TempPrimOwner3@domain.com',
            userId: 'TempPrimaryOwnerId3',
          },
          secondaryOwner: {
            name: 'TempSecondaryOwner3',
            emailAddress: 'TempSecondaryOwner3@domain.com',
            userId: 'TempSecondaryOwnerId3',
          },
          thirdpartyContact: 'TempThirdpartyContact3@domain.com',
          assignedTo: {
            name: 'TempAssignee3',
            emailAddress: 'TempAssignee3@domain.com',
            userId: 'TempAssigneeId3',
          },
          seniorLeader: {
            name: 'TempSeniorLead3',
            emailAddress: 'TempSeniorLead3@domain.com',
            userId: 'TempSeniorLeadId3',
          },
          federatedSecTeam: 'TempFederatedSecTeam3',
          parentTpta: 'TPTA19029954',
          awsDataInvolved: Checkbox.CHECKED,
          abandoned: Checkbox.UNCHECKED,
          watchList: 'TempWatchList3',
        },
        intakeAssessmentSchedule: {
          actualStartDate: new Date(),
          actualEndDate: new Date('2023-04-04'),
          expectedOnSiteDate: new Date(),
        },
        tieringMetrics: {
          dataType: 'TempDataType3',
          dataClassification: 'TempClassification3',
          dataSharingMechanism: 'TempDataSharingMechanism3',
          countriesInvolved: [Countries.US, Countries.CANADA, Countries.ITALY, Countries.JAPAN],
          volumeOfRecords: 50_000,
          directGovtSharing: true,
          contingentWorkers: true,
          secretThirdParty: true,
          contractualValue: 50_00_000,
          contractCentralMatterNumber: 1_77_00_000,
          bindleInfo: 'TempBindleInfo3',
          awsAccountId: 'AWS12346890',
          projectGoLiveDate: new Date('2023-03-28'),
          projectEndDate: new Date('2023-06-28'),
          workDocsConduitAccId: 'CDT45237843',
        },
        followupAssessmentNeededOptions: {
          saas: Checkbox.CHECKED,
          giftCard: Checkbox.UNCHECKED,
          s3: Checkbox.CHECKED,
          payment: Checkbox.CHECKED,
        },
      },
    },
    isFavorited: true,
    activities: TempProjectActivities,
    tpsRecordId: '083c67b384932110224f63b44b31bcbd',
  },
  {
    assessmentId: 'ASS53445000',
    projectName: 'TempProject4',
    vendorName: 'TempVendor4',
    tptaNumber: 'TPTA0025353',
    state: State.AWAITING_REQUESTER,
    severity: Priority.MEDIUM,
    details: {
      member: {
        tieringAssessment: {
          assessmentOutcome: AssessmentOutcomeStatus.MET,
          number: 'TA1894999999',
          name: 'Assement4',
          state: ProjectState.DDQ,
          thirdparty: 'TempThirdParty4',
          thirdpartyTier: Tier.THREE,
          deepDiveAssessment: 'TempDeepAss4',
          productOrService: 'TempProdSer4',
          businessPurpose: 'TempBussPur4',
          terminated: Checkbox.UNCHECKED,
          hasSecurityFollowUp: true,
        },
        ownership: {
          requesterSTeam: {
            name: 'TempReqTeam4',
            emailAddress: 'TempTeamEmail4@domain.com',
            userId: 'TempUserId4',
          },
          department: 'TempDep4',
          requester: {
            name: 'TempRequester4',
            emailAddress: 'TempRequesterEmail4@domain.com',
            userId: 'TempRequesterId4',
          },
          primaryOwner: {
            name: 'TempPrimOwner4',
            emailAddress: 'TempPrimOwner4@domain.com',
            userId: 'TempPrimaryOwnerId4',
          },
          secondaryOwner: {
            name: 'TempSecondaryOwner4',
            emailAddress: 'TempSecondaryOwner4@domain.com',
            userId: 'TempSecondaryOwnerId4',
          },
          thirdpartyContact: 'TempThirdpartyContact4@domain.com',
          assignedTo: {
            name: 'TempAssignee4',
            emailAddress: 'TempAssignee4@domain.com',
            userId: 'TempAssigneeId4',
          },
          seniorLeader: {
            name: 'TempSeniorLead4',
            emailAddress: 'TempSeniorLead4@domain.com',
            userId: 'TempSeniorLeadId4',
          },
          federatedSecTeam: 'TempFederatedSecTeam4',
          parentTpta: 'TPTA19028843',
          awsDataInvolved: Checkbox.UNCHECKED,
          abandoned: Checkbox.CHECKED,
          watchList: 'TempWatchList4',
        },
        intakeAssessmentSchedule: {
          actualStartDate: new Date(),
          actualEndDate: new Date('2023-04-04'),
          expectedOnSiteDate: new Date(),
        },
        tieringMetrics: {
          dataType: 'TempDataType4',
          dataClassification: 'TempClassification4',
          dataSharingMechanism: 'TempDataSharingMechanism4',
          countriesInvolved: [
            Countries.US,
            Countries.INDIA,
            Countries.ITALY,
            Countries.JAPAN,
            Countries.GERMANY,
            Countries.EGYPT,
          ],
          volumeOfRecords: 70_000,
          directGovtSharing: true,
          contingentWorkers: true,
          secretThirdParty: true,
          contractualValue: 70_00_000,
          contractCentralMatterNumber: 2_88_00_000,
          bindleInfo: 'TempBindleInfo4',
          awsAccountId: 'AWS12347979',
          projectGoLiveDate: new Date('2023-03-28'),
          projectEndDate: new Date('2023-06-28'),
          workDocsConduitAccId: 'CDT45237999',
        },
        followupAssessmentNeededOptions: {
          saas: Checkbox.UNCHECKED,
          giftCard: Checkbox.CHECKED,
          s3: Checkbox.UNCHECKED,
          payment: Checkbox.UNCHECKED,
        },
      },
    },
    isFavorited: true,
    activities: TempProjectActivities,
    tpsRecordId: '0ba3b2e799936510d5500cec80e093ac',
  },
  {
    assessmentId: 'ASS53445112',
    projectName: 'TempProject5',
    vendorName: 'TempVendor5',
    tptaNumber: 'TPTA0025352',
    state: State.AWAITING_ASSESSOR,
    severity: Priority.LOW,
    details: {
      member: {
        tieringAssessment: {
          assessmentOutcome: AssessmentOutcomeStatus.EXCEPTIONS,
          number: 'TA1894989897',
          name: 'Assement5',
          state: ProjectState.ISSUE,
          thirdparty: 'TempThirdParty5',
          thirdpartyTier: Tier.THREE,
          deepDiveAssessment: 'TempDeepAss5',
          productOrService: 'TempProdSer5',
          businessPurpose: 'TempBussPur5',
          terminated: Checkbox.CHECKED,
          hasSecurityFollowUp: true,
        },
        ownership: {
          requesterSTeam: {
            name: 'TempReqTeam5',
            emailAddress: 'TempTeamEmail5@domain.com',
            userId: 'TempUserId5',
          },
          department: 'TempDep5',
          requester: {
            name: 'TempRequester5',
            emailAddress: 'TempRequesterEmail5@domain.com',
            userId: 'TempRequesterId5',
          },
          primaryOwner: {
            name: 'TempPrimOwner5',
            emailAddress: 'TempPrimOwner5@domain.com',
            userId: 'TempPrimaryOwnerId5',
          },
          secondaryOwner: {
            name: 'TempSecondaryOwner5',
            emailAddress: 'TempSecondaryOwner5@domain.com',
            userId: 'TempSecondaryOwnerId5',
          },
          thirdpartyContact: 'TempThirdpartyContact5@domain.com',
          assignedTo: {
            name: 'TempAssignee5',
            emailAddress: 'TempAssignee5@domain.com',
            userId: 'TempAssigneeId5',
          },
          seniorLeader: {
            name: 'TempSeniorLead5',
            emailAddress: 'TempSeniorLead5@domain.com',
            userId: 'TempSeniorLeadId5',
          },
          federatedSecTeam: 'TempFederatedSecTeam5',
          parentTpta: 'TPTA19028856',
          awsDataInvolved: Checkbox.CHECKED,
          abandoned: Checkbox.UNCHECKED,
          watchList: 'TempWatchList5',
        },
        intakeAssessmentSchedule: {
          actualStartDate: new Date('2023-04-04'),
          actualEndDate: new Date(),
          expectedOnSiteDate: new Date(),
        },
        tieringMetrics: {
          dataType: 'TempDataType5',
          dataClassification: 'TempClassification5',
          dataSharingMechanism: 'TempDataSharingMechanism5',
          countriesInvolved: [
            Countries.US,
            Countries.INDIA,
            Countries.CZ,
            Countries.JAPAN,
            Countries.BRAZIL,
            Countries.ITALY,
            Countries.GERMANY,
          ],
          volumeOfRecords: 90_000,
          directGovtSharing: true,
          contingentWorkers: false,
          secretThirdParty: true,
          contractualValue: 90_00_000,
          contractCentralMatterNumber: 3_85_00_000,
          bindleInfo: 'TempBindleInfo5',
          awsAccountId: 'AWS12347981',
          projectGoLiveDate: new Date('2023-06-01'),
          projectEndDate: new Date('2023-06-28'),
          workDocsConduitAccId: 'CDT45237805',
        },
        followupAssessmentNeededOptions: {
          saas: Checkbox.CHECKED,
          giftCard: Checkbox.UNCHECKED,
          s3: Checkbox.CHECKED,
          payment: Checkbox.CHECKED,
        },
      },
    },
    isFavorited: false,
    activities: TempProjectActivities,
    tpsRecordId: '84537627f4936510aac15f823ef50598',
  },
  {
    assessmentId: 'ASS53445155',
    projectName: 'TempProject6',
    vendorName: 'TempVendor6',
    tptaNumber: 'TPTA0025351',
    state: State.AWAITING_VENDOR,
    severity: Priority.HIGH,
    details: {
      member: {
        tieringAssessment: {
          assessmentOutcome: AssessmentOutcomeStatus.NOT_MET,
          number: 'TA1894987070',
          name: 'Assement6',
          state: ProjectState.ISSUE,
          thirdparty: 'TempThirdParty6',
          thirdpartyTier: Tier.ONE,
          deepDiveAssessment: 'TempDeepAss6',
          productOrService: 'TempProdSer6',
          businessPurpose: 'TempBussPur6',
          terminated: Checkbox.UNCHECKED,
          hasSecurityFollowUp: true,
        },
        ownership: {
          requesterSTeam: {
            name: 'TempReqTeam6',
            emailAddress: 'TempTeamEmail6@domain.com',
            userId: 'TempUserId6',
          },
          department: 'TempDep6',
          requester: {
            name: 'TempRequester6',
            emailAddress: 'TempRequesterEmail6@domain.com',
            userId: 'TempRequesterId6',
          },
          primaryOwner: {
            name: 'TempPrimOwner6',
            emailAddress: 'TempPrimOwner6@domain.com',
            userId: 'TempPrimaryOwnerId6',
          },
          secondaryOwner: {
            name: 'TempSecondaryOwner6',
            emailAddress: 'TempSecondaryOwner6@domain.com',
            userId: 'TempSecondaryOwnerId6',
          },
          thirdpartyContact: 'TempThirdpartyContact6@domain.com',
          assignedTo: {
            name: 'TempAssignee6',
            emailAddress: 'TempAssignee6@domain.com',
            userId: 'TempAssigneeId6',
          },
          seniorLeader: {
            name: 'TempSeniorLead6',
            emailAddress: 'TempSeniorLead6@domain.com',
            userId: 'TempSeniorLeadId6',
          },
          federatedSecTeam: 'TempFederatedSecTeam6',
          parentTpta: 'TPTA19028909',
          awsDataInvolved: Checkbox.UNCHECKED,
          abandoned: Checkbox.CHECKED,
          watchList: 'TempWatchList6',
        },
        intakeAssessmentSchedule: {
          actualStartDate: new Date(),
          actualEndDate: new Date('2023-04-04'),
          expectedOnSiteDate: new Date(),
        },
        tieringMetrics: {
          dataType: 'TempDataType6',
          dataClassification: 'TempClassification6',
          dataSharingMechanism: 'TempDataSharingMechanism6',
          countriesInvolved: [
            Countries.US,
            Countries.INDIA,
            Countries.JAPAN,
            Countries.BRAZIL,
            Countries.EGYPT,
            Countries.FRANCE,
          ],
          volumeOfRecords: 60_000,
          directGovtSharing: true,
          contingentWorkers: false,
          secretThirdParty: false,
          contractualValue: 40_00_000,
          contractCentralMatterNumber: 5_77_00_000,
          bindleInfo: 'TempBindleInfo6',
          awsAccountId: 'AWS12347999',
          projectGoLiveDate: new Date('2023-06-05'),
          projectEndDate: new Date('2023-06-05'),
          workDocsConduitAccId: 'CDT45237811',
        },
        followupAssessmentNeededOptions: {
          saas: Checkbox.CHECKED,
          giftCard: Checkbox.UNCHECKED,
          s3: Checkbox.UNCHECKED,
          payment: Checkbox.CHECKED,
        },
      },
    },
    isFavorited: true,
    activities: TempProjectActivities,
    tpsRecordId: '7c4b122ff4536510aac15f823ef50563',
  },
];

//need data from the backend
export const TempConsultsPayload: TempConsults[] = [
  {
    id: 'dklfjgn23j3',
    consultName: 'Consult Name',
    consultDescription: 'Consult Description',
    tprtNum: 'TPRT098765432',
    dateCreated: new Date(),
    state: State.AWAITING_ASSESSOR,
    status: 'open',
  },
  {
    id: 'dl123456csdf',
    consultName: 'Consult Name',
    consultDescription: 'Consult Description',
    tprtNum: 'TPRT098765432',
    dateCreated: new Date(),
    state: State.AWAITING_ASSESSOR,
    status: 'open',
  },
  {
    id: 'nfth233983',
    consultName: 'Consult Name',
    consultDescription: 'Consult Description',
    tprtNum: 'TPRT098765432',
    dateCreated: new Date(),
    state: State.AWAITING_VENDOR,
    status: 'open',
  },
  {
    id: 'dlkfjh325345',
    consultName: 'Consult Name',
    consultDescription: 'Consult Description',
    tprtNum: 'TPRT098765432',
    dateCreated: new Date(),
    state: State.AWAITING_REQUESTER,
    status: 'open',
  },
  {
    id: 'oasjd12398f',
    consultName: 'Consult Name',
    consultDescription: 'Consult Description',
    tprtNum: 'TPRT098765432',
    dateCreated: new Date(),
    state: State.CLOSED,
    status: 'closed',
  },
  {
    id: 'sdkjfh12123',
    consultName: 'Consult Name',
    consultDescription: 'Consult Description',
    tprtNum: 'TPRT098765432',
    dateCreated: new Date(),
    state: State.CLOSED,
    status: 'closed',
  },
  {
    id: 'voifais21123',
    consultName: 'Consult Name',
    consultDescription: 'Consult Description',
    tprtNum: 'TPRT098765432',
    dateCreated: new Date(),
    state: State.CLOSED,
    status: 'closed',
  },
];

//Need from the backend
export const TempNotifications: UpdateItemProps[] = [
  {
    id: 'asdijh11',
    entity: 'User A',
    payload: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    isSystemNotification: true,
    timestamp: new Date(),
  },
  {
    id: 'asjodh1j2b',
    entity: 'User B',
    payload: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    isSystemNotification: true,
    timestamp: new Date(),
  },
  {
    id: '12lij3j0v',
    entity: 'User C',
    payload: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    isSystemNotification: true,
    timestamp: new Date(),
  },
];

//needed from the backend model
export const InfoBannerTempNotifications = [
  { id: 1, projectName: 'TPTA129932', notificationText: 'awaiting_response' },
  { id: 2, projectName: 'TPTA123122', notificationText: 'nearing_remediation' },
  { id: 3, projectName: 'TPTA198127', notificationText: 'idle_needs_attention' },
];

//need from the backend
export const TempTakeActionNotifications: Activity[] = [
  {
    id: 'alisudh1iub',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ASSESSMENT,
    timestamp: new Date(),
  },
  {
    id: 'asdklj12',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ASSESSMENT,
    timestamp: new Date('2023-06-12'),
  },
  {
    id: 'p8u1n212',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ASSESSMENT,
    timestamp: new Date('2023-06-11'),
  },
  {
    id: '2lk3n42',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ASSESSMENT,
    timestamp: new Date(),
  },
  {
    id: 'ofgitj23',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ASSESSMENT,
    timestamp: new Date(),
  },
  {
    id: 'akj12jn12n2',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ISSUES,
    timestamp: new Date(),
  },
  {
    id: 'clkj12lkj12',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ISSUES,
    timestamp: new Date(),
  },
  {
    id: '1098fh33',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ISSUES,
    timestamp: new Date(),
  },
  {
    id: 'vlkjh12',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ISSUES,
    timestamp: new Date(),
  },
  {
    id: 'lk2j3lk1',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ISSUES,
    timestamp: new Date(),
  },
  {
    id: '3klj3hh2',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.ISSUES,
    timestamp: new Date(),
  },
  {
    id: 'l2j2n30vu',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.CONSULTS,
    timestamp: new Date(),
  },
  {
    id: 'wd78y12h12',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.CONSULTS,
    timestamp: new Date(),
  },
  {
    id: 'adlfkj12kjn12',
    entity: 'Project Name',
    action: 'Action Description',
    payload: TakeActionItemType.CONSULTS,
    timestamp: new Date(),
  },
];

//     notificationId: 'alisudh1iub',
//     projectName: 'Project Name',
//     actionDescription: 'Action Description',
//     type: TakeActionItemType.ASSESSMENT,
