export type RoleType = 'requester' | 'vendor' | 'assessor';
interface UserData {
  name: string;
  role: RoleType;
}

export type UserMetadata = UserData | undefined;

export const TempUsers: UserMetadata[] = [
  {
    name: 'Verna Gallegos',
    role: 'assessor',
  },
  {
    name: 'Christoph Waltz',
    role: 'vendor',
  },
  {
    name: 'Venkat Gupta',
    role: 'requester',
  },
];
