import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import MuiCircularProgress from '@mui/material/CircularProgress';
import MuiTypography from '@mui/material/Typography';
/* 
  NOTE
  ================================================================================= 
  Using the location state (set in PrivateRouter Component) so we can redirect 
  back to the original page we came from before authentication.

  We pass it as customState to Cognito and once we get the response and listen for 
  the customOAuthState event we can then redirect.
  
  ** See useAuth hook for the receiving end of the call. **
  =================================================================================
*/

const FederatedSignIn = (): React.ReactElement => {
  Auth.currentAuthenticatedUser()
    .then((_user) => {
      if (process.env.NODE_ENV == 'development') console.log('_user', _user);
    })
    .catch(() => {
      Auth.federatedSignIn({
        customProvider: 'AmznFederated',
      }).catch((err) => {
        console.log(err);
      });
    });

  return (
    <React.Fragment>
      <MuiTypography>Sign In in Progress....</MuiTypography>
      <MuiCircularProgress />
    </React.Fragment>
  );
};

export default FederatedSignIn;
