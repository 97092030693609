import React from 'react';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiAvatar from '@mui/material/Avatar';
import MuiStack from '@mui/material/Stack';
import { REQUESTOR_GREEN, ASSESSOR_ORANGE, VENDOR_PURPLE } from '../../constants/constants';
import { useUserContext } from '../../context/UserProvider';
import { RoleType } from '../../data/UserMetadata';
import './TopBarNav.css';

//This function is to show the color for each type of the role.
function getColor(role: RoleType) {
  switch (role) {
    case 'requester':
      return REQUESTOR_GREEN;
    case 'assessor':
      return ASSESSOR_ORANGE;
    case 'vendor':
      return VENDOR_PURPLE;
  }
}

function stringAvatar(name: string, role: RoleType) {
  return {
    sx: {
      marginTop: 0,
      width: '43px',
      height: '43px',
      fontSize: '21px',
      fontFamily: 'Amazon Ember',
      bgcolor: getColor(role),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function ProfileAvatar({ bundle }: WithBundleProps) {
  const user = useUserContext();

  if (user == null) return <></>;

  return (
    <MuiStack direction="row" spacing={2} sx={{ marginRight: '80px', fontFamily: 'Amazon Ember' }}>
      {
        <>
          <MuiAvatar {...stringAvatar(user.name, user.role)} />
          <div className="profile-thumbnail-styling">
            <div className="user-name-thumbnail">{user.name}</div>
            <div className="user-role-thumbnail">{bundle.getMessage(user.role)}</div>
          </div>
        </>
      }
    </MuiStack>
  );
}

export default withBundle('components.ProfileAvatar')(ProfileAvatar);
