import React, { useState, useReducer, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { MessageBundle } from '@amzn/arb-tools';
import MuiLink from '@mui/material/Link';
import MuiCollapse from '@mui/material/Collapse';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material';
import Button from '../../components/Button/Button';
import FilterBar from '../../components/FilterBar/FilterBar';
import { CONSULTS_PAGE, NEW_CONSULT } from '../../constants/linkConstants';
import { TempConsultsPayload } from '../../data/TempRecordMetadata';
import { GRAY } from '../../constants/constants';
import DropdownIcon from '../../images/dropdownArrow.svg';
import './ConsultsPage.css';
import { State, TempConsults } from '../../../src/data/RecordMetadata';
import filterReducer from '../../components/FilterBar/FilterReducer';
import useAuth from '../../hooks/useAuth';
import FederatedSignIn from '../../components/Login/FederatedSignIn';

const StyledLink = styled(MuiLink)({
  textDecoration: 'none',
  color: 'black',
});

const StyledSpan = styled('span')({
  color: GRAY,
});

const ConsultDropdown = styled('button')({
  border: 'none',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'unset',
    color: 'unset',
    cursor: 'pointer',
  },
});

function stateLabel(labelType: State) {
  switch (labelType) {
    case State.AWAITING_ASSESSOR:
      return 'assessor';
    case State.AWAITING_REQUESTER:
      return 'requester';
    case State.AWAITING_VENDOR:
      return 'vendor';
    case State.CLOSED:
      return 'closed';
  }
}

function createConsult(consult: TempConsults, bundle: MessageBundle, display: boolean) {
  return (
    <React.Fragment key={consult.id}>
      {display ? (
        <StyledLink
          {...{ component: Link, to: `${CONSULTS_PAGE.href}/${consult.tprtNum}` }}
          role="row"
          className="consult-page-table-row"
        >
          <div role="cell" className="consults-page-table-name-description">
            <p>
              {bundle.formatMessage('record_number_cell', {
                consultName: consult.consultName,
                consultDescription: consult.consultDescription,
                tprtNum: consult.tprtNum,
                span: (children: string) => <StyledSpan key="1">{children}</StyledSpan>,
              })}
            </p>
          </div>
          <div role="cell">
            <p>{bundle.formatMessage('date_created_cell', { dateOpened: consult.dateCreated })}</p>
          </div>
          <div role="cell">
            <p className={`state-label ${stateLabel(consult.state)}-label`}>
              {bundle.formatMessage('state_cell', { projectState: consult.state })}
            </p>
          </div>
        </StyledLink>
      ) : null}
    </React.Fragment>
  );
}

function ConsultsPage({ bundle }: WithBundleProps) {
  const navigate = useNavigate();
  const handleOnClick = () => navigate(`${CONSULTS_PAGE.href}${NEW_CONSULT.href}`);
  const [openExpanded, setOpenExpanded] = React.useState(true);
  const [closedExpanded, setClosedExpanded] = React.useState(false);
  const [state, dispatch] = useReducer(filterReducer, { componentData: [...TempConsultsPayload] });
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useAuth();
  //memoizing this state properties to avoid unnecessary renders of Filter Child
  const memoizedFilterFields = useMemo(() => state.filterFields, [state.filterFields]);
  const memoizedLabels = useMemo(() => state.labels, [state.labels]);
  const memoizedDispatchFn = useMemo(() => dispatch, [dispatch]);

  //useEffect for initial state to contain filtermap, label sets at component level
  useEffect(() => {
    dispatch({
      type: 'initialSetup',
      payload: {
        labels: ['state'],
        bundle,
      },
    });
    setLoading(() => false);
  }, []);
  const handleOpenExpand = () => setOpenExpanded(!openExpanded);
  const handleClosedExpand = () => setClosedExpanded(!closedExpanded);

  return user ? (
    <div className="consults-page-container">
      {loading ? (
        <MuiCircularProgress />
      ) : (
        <React.Fragment>
          <FilterBar
            filterFields={memoizedFilterFields}
            filterLabels={memoizedLabels}
            dispatch={memoizedDispatchFn}
            title={'my_consults'}
          />
          <Button onClick={handleOnClick} type="dark" style={{ padding: '5px 7px', marginTop: '30px' }}>
            {bundle.getMessage(NEW_CONSULT.label)}
          </Button>
          <div role="table" className="consult-page-table">
            <div role="row" className="consult-page-table-row consult-page-table-row-header">
              <div role="columnheader">
                <p>{bundle.getMessage('record_number_header')}</p>
              </div>
              <div role="columnheader">
                <p>{bundle.getMessage('date_created_header')}</p>
              </div>
              <div role="columnheader">
                <p>{bundle.getMessage('state_header')}</p>
              </div>
            </div>
            <div aria-hidden className="open-consults">
              <ConsultDropdown
                role="rowgroup"
                className="consults-page-inner-header open-consults-header"
                onClick={handleOpenExpand}
              >
                <img
                  src={DropdownIcon}
                  style={{
                    height: '25px',
                    width: '25px',
                    transform: !openExpanded ? 'rotate(-90deg)' : 'rotate(0deg)',
                    transitionDuration: '500ms',
                  }}
                  aria-expanded={openExpanded}
                  aria-label="show more"
                />
                <p>{bundle.getMessage('open_consults')}</p>
              </ConsultDropdown>
              <MuiCollapse in={openExpanded} timeout="auto" unmountOnExit className="consults-table">
                {state.componentData.map((consult: TempConsults, index: number) => {
                  if (consult.status != 'open') return;
                  return createConsult(consult, bundle, state.displayData[index]);
                })}
              </MuiCollapse>
            </div>
            <div aria-hidden className="closed-consults">
              <ConsultDropdown
                role="rowgroup"
                className="consults-page-inner-header closed-consults-header"
                onClick={handleClosedExpand}
              >
                <img
                  src={DropdownIcon}
                  style={{
                    height: '25px',
                    width: '25px',
                    transform: !closedExpanded ? 'rotate(-90deg)' : 'rotate(0deg)',
                    transitionDuration: '500ms',
                  }}
                  aria-expanded={closedExpanded}
                  aria-label="show more"
                />
                <p>{bundle.getMessage('closed_consults')}</p>
              </ConsultDropdown>
              <MuiCollapse in={closedExpanded} timeout="auto" unmountOnExit className="consults-table">
                {state.componentData.map((consult: TempConsults, index: number) => {
                  if (consult.status != 'closed') return;
                  return createConsult(consult, bundle, state.displayData[index]);
                })}
              </MuiCollapse>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  ) : (
    <FederatedSignIn />
  );
}

export default withBundle('pages.ConsultsPage')(ConsultsPage);
