import React from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import FederatedSignIn from '../../components/Login/FederatedSignIn';

export default function TempIntakeQuestionnaire() {
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  const { user } = useAuth();
  return user ? (
    <div>
      <p>Temp Intake Questionnaire Page</p>
    </div>
  ) : (
    <FederatedSignIn />
  );
}
