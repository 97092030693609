import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../../components/App';
import LandingPage from '../../pages/landingPage/LandingPage';
import IssuesPage from '../../pages/issuesPage/IssuesPage';
import TasksPage from '../../pages/tasksPage/TasksPage';
import ConsultsPage from '../../pages/consultsPage/ConsultsPage';
import RequestNewConsultPage from '../../pages/consultsPage/RequestNewConsultPage';
import SurveysPage from '../../pages/surveysPage/SurveysPage';
import CompleteAssessments from '../../pages/completeAssessments/CompleteAssessments';
import SearchPage from '../../pages/searchResultsPage/SearchResultsPage';
import TpsCatalogPage from '../../pages/tpsCatalogPage/TpsCatalogPage';
import StartIntakePage from '../../pages/startIntakePage/StartIntakePage';
import MyProfilePage from '../../pages/myProfilePage/MyProfilePage';
import ManageTeamPage from '../../pages/manageTeamPage/ManageTeamPage';
import ChangePasswordPage from '../../pages/changePasswordPage/ChangePasswordPage';
import TempConsult from '../../pages/tempPages/TempConsult';
import TempIssue from '../../pages/tempPages/TempIssue';
import TempDdq from '../../pages/tempPages/TempDdq';
import TempIntakeQuestionnaire from '../../pages/tempPages/TempIntakeQuestionnaire';
import ErrorPage from '../../pages/errorPages/ErrorPage';
import {
  HOME_PAGE,
  DASHBOARD,
  START_INTAKE,
  ISSUES_PAGE,
  TASKS_PAGE,
  CONSULTS_PAGE,
  SURVEYS_PAGE,
  COMPLETE_ASSESSMENTS_PAGE,
  SEARCH_RESULTS,
  TPS_CATALOG,
  MY_PROFILE,
  MANAGE_TEAM,
  CHANGE_PASSWORD,
  VIEW_DDQ,
  VIEW_INTAKE,
  NEW_CONSULT,
} from '../../constants/linkConstants';
import ProjectLandingPage from '../../pages/projectLandingPage/ProjectLandingPage';
import TempCompleteAssessmentsDetails from '../../pages/tempPages/TempCompleteAssessmentDetails';
import FederatedSignIn from '../Login/FederatedSignIn';

const router = createBrowserRouter([
  {
    path: `${HOME_PAGE.href}`,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${HOME_PAGE.href}/login`,
        element: <FederatedSignIn />,
      },
      {
        path: DASHBOARD.href,
        element: <LandingPage />,
      },
      {
        path: `${DASHBOARD.href}:projectId`,
        element: <ProjectLandingPage />,
      },
      {
        path: `${DASHBOARD.href}:projectId${VIEW_DDQ.href}`,
        element: <TempDdq />,
      },
      {
        path: `${DASHBOARD.href}:projectId${VIEW_INTAKE.href}`,
        element: <TempIntakeQuestionnaire />,
      },
      {
        path: ISSUES_PAGE.href,
        element: <IssuesPage />,
      },
      {
        path: `${ISSUES_PAGE.href}/:issueId`,
        element: <TempIssue />,
      },
      {
        path: TASKS_PAGE.href,
        element: <TasksPage />,
      },
      {
        path: `${TASKS_PAGE.href}/:taskId`,
        element: <TasksPage />,
      },
      {
        path: CONSULTS_PAGE.href,
        element: <ConsultsPage />,
      },
      {
        path: `${CONSULTS_PAGE.href}${NEW_CONSULT.href}`,
        element: <RequestNewConsultPage />,
      },
      {
        path: `${CONSULTS_PAGE.href}/:consultsId`,
        element: <TempConsult />,
      },
      {
        path: SURVEYS_PAGE.href,
        element: <SurveysPage />,
      },
      {
        path: COMPLETE_ASSESSMENTS_PAGE.href,
        element: <CompleteAssessments />,
      },
      {
        path: `${COMPLETE_ASSESSMENTS_PAGE.href}/:completeAssessmentId`,
        element: <TempCompleteAssessmentsDetails />,
      },
      {
        path: SEARCH_RESULTS.href,
        element: <SearchPage />,
      },
      {
        path: TPS_CATALOG.href,
        element: <TpsCatalogPage />,
      },
      {
        path: MY_PROFILE.href,
        element: <MyProfilePage />,
      },
      {
        path: MANAGE_TEAM.href,
        element: <ManageTeamPage />,
      },
      {
        path: CHANGE_PASSWORD.href,
        element: <ChangePasswordPage />,
      },
      {
        path: START_INTAKE.href,
        element: <StartIntakePage />,
      },
    ],
  },
]);

export default function PortalRouter() {
  return <RouterProvider router={router} />;
}
