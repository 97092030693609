import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PantherI18nWrapper from './components/PantherI18nWrapper';
import AppWrapper from './components/AppWrapper';
import PortalRouter from './components/Router/PortalRouter';
import { UserProvider } from './context/UserProvider';
import { DataProvider } from './context/DataProvider';
import { TempUsers } from './data/UserMetadata';
import { Amplify } from 'aws-amplify';
import AmplifyCongfiguration from '../configuration/amplifyConfig.json';
import { AuthProvider } from './hooks/useAuth';
import './i18n';
import 'src/styles.css';

const tempUser = TempUsers[0];
const queryClient = new QueryClient();

//amplify underlying uses Axios to make the API requests for Cognito endpoints to collect data
//TODO we can use AWS AppSync to get the configuraion at run time
//https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js/#aws-regional-endpoints
Amplify.configure({
  ...AmplifyCongfiguration.beta,
  oauth: {
    ...AmplifyCongfiguration.beta.oauth,
    redirectSignIn:
      process.env.NODE_ENV == 'development' ? 'http://localhost:4321' : AmplifyCongfiguration.beta.oauth.redirectSignIn,
    redirectSignOut:
      process.env.NODE_ENV == 'development'
        ? 'http://localhost:4321'
        : AmplifyCongfiguration.beta.oauth.redirectSignOut,
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <UserProvider user={tempUser}>
      <AppWrapper>
        <PantherI18nWrapper>
          <DataProvider>
            <AuthProvider>
              <PortalRouter />
            </AuthProvider>
          </DataProvider>
        </PantherI18nWrapper>
      </AppWrapper>
    </UserProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
