import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import MuiNavigateNextIcon from '@mui/icons-material/NavigateNext';

import { DEFAULT_BREADCRUMBS, LINKS } from '../../constants/linkConstants';
import { GRAY, LINK_BLUE } from '../../constants/constants';
import './Breadcrumbs.css';

function Breadcrumbs({ bundle }: WithBundleProps) {
  const location = useLocation().pathname;
  const [breadcrumbs, setBreadcrumbs] = useState(DEFAULT_BREADCRUMBS);

  useEffect(() => {
    const pathnames = location.split('/').filter((x) => x);
    const breadcrumbTrail = DEFAULT_BREADCRUMBS.concat(
      pathnames.map((path) => {
        if (!LINKS[path]) {
          return { label: path, href: path, isDynamic: true }; // For dynamic links to projects
        }
        return path.length === 0 ? LINKS['dashboard'] : LINKS[path];
      }),
    );
    setBreadcrumbs(breadcrumbTrail);
  }, [location]);

  return (
    <div role="presentation" className="breadcrumb-background">
      <MuiBreadcrumbs separator={<MuiNavigateNextIcon className="breadcrumb-seperator" />} aria-label="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => {
          if (index === breadcrumbs.length - 1) {
            return (
              <MuiLink key={index} fontFamily="Amazon Ember" color={GRAY} underline="none">
                {breadcrumb.isDynamic ? breadcrumb.label : bundle.getMessage(breadcrumb.label)}
              </MuiLink>
            );
          }
          return (
            <MuiLink
              {...{ component: Link, to: breadcrumb.href }}
              key={index}
              fontFamily="Amazon Ember"
              color={LINK_BLUE}
              underline="none"
            >
              {breadcrumb.isDynamic ? breadcrumb.label : bundle.getMessage(breadcrumb.label)}
            </MuiLink>
          );
        })}
      </MuiBreadcrumbs>
    </div>
  );
}

export default withBundle('components.Breadcrumbs')(Breadcrumbs);
