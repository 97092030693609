import React from 'react';
import { Outlet } from 'react-router';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import InfoBanner from './InfoBanner/InfoBanner';
import TopBarNav from './TopBarNav/TopBarNav';
import SideBar from './SideBar/SideBar';
import TodoList from './TodoList/TodoList';
import { Auth, API } from 'aws-amplify';
// TODO REMOVE; This is temporariliy showing TanStack working
import { useQuery } from '@tanstack/react-query';
import useAuth from '../hooks/useAuth';
import FederatedSignIn from './Login/FederatedSignIn';

// eslint-disable-next-line
export function Example(): React.ReactElement {
  const { isLoading, error, data } = useQuery({
    queryKey: ['repoData'],
    queryFn: () => fetch('https://jsonplaceholder.typicode.com/todos/1').then((res) => res.json()),
  });

  if (isLoading) return <p>{'Loading...'}</p>;

  if (error) return <p>{'error...'}</p>;

  return (
    <div>
      <h1>Placeholder data to show TanStack Query</h1>
      <strong>UserId: </strong>
      <span>{data.userId}, </span>
      <strong>Title: </strong>
      <span>{data.title}, </span>
      <strong>Completed: </strong>
      <span>{data.completed ? 'complete' : 'incomplete'}</span>
    </div>
  );
}

function App() {
  const { user } = useAuth();

  const invokeGatewayApi = async () => {
    try {
      const tokens = await Auth.currentSession();
      const authToken = tokens.getIdToken().getJwtToken();
      const response = await API.get('beta-ApiGateway', '/api/v1/assessments/vendorID', {
        headers: {
          Authorization: authToken,
          token: authToken,
        },
        response: true,
      });

      if (process.env.NODE_ENV == 'development') console.log('response', response);
    } catch (error) {}
  };

  if (process.env.NODE_ENV == 'development') console.log('user', user);

  if (user) {
    invokeGatewayApi();
    return (
      <React.Fragment>
        <header>
          <TopBarNav />
          <Breadcrumbs />
        </header>
        <aside>
          <SideBar />
        </aside>
        <main>
          <div className="main-content-container">
            {/* TODO Remove this once the above code is also removed. */}
            <Example />
            <InfoBanner />

            {/* Temporary spacing element inplace of the accordion */}
            <div style={{ padding: '20px 0' }}></div>
            <TodoList />
            <Outlet />
          </div>
        </main>
      </React.Fragment>
    );
  } else {
    return <FederatedSignIn />;
  }
}

export default App;
