import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';

import MuiAvatar from '@mui/material/Avatar';
import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material';

import { ClipboardSvg, UpdateSvg, ClipboardNotificationSvg } from './TodoListSvg';
import { GRAY, LINK_BLUE } from '../../constants/constants';
import { PROJECT_DETAILS } from '../../constants/linkConstants';
import './TodoList.css';
import { UpdateItemProps } from '../../data/RecordMetadata';
import { TempNotifications, TempTakeActionNotifications, TempSortedList } from '../../data/TempRecordMetadata';

const CardHeaderStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '70%',
  paddingLeft: '20px',
  paddingBottom: '30px',
});

const CardHeaderButton = styled('button')({
  border: 'none',
  backgroundColor: 'white',
  padding: 0,
  margin: 0,
});

const EmptyTodo = styled('p')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const TodoItemLink = styled(Link)({
  textDecoration: 'none',
  color: 'unset',
});
const UpdateItemLink = TodoItemLink;

const Avatar = styled(MuiAvatar)({
  height: '24px',
  width: '24px',
  fontSize: '10px',
});

const LargeFont = styled('p')({
  margin: '0 4px',
  fontSize: '13px',
});

const SmallFont = styled('p')({
  margin: '0 4px',
  fontSize: '9px',
});

const UpdatePayload = styled('p')({
  margin: '0 4px',
  paddingTop: '5px',
  fontSize: '11px',
});

const SmallCircle = styled('div')({
  height: '8px',
  width: '8px',
  backgroundColor: GRAY,
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: '15px',
  marginTop: '3px',
  marginLeft: '-4px',
});

const Break = styled('div')({
  flexBasis: '100%',
  height: 0,
});

function TodoList({ bundle }: WithBundleProps) {
  const [anchorEl, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const [isUpdates, setIsUpdates] = useState(false);

  return (
    <div className="take-action-container">
      <div
        role="button"
        aria-controls={open ? 'take-action-menu-content' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        className="take-action-button"
        id="take-action-icon-button"
      >
        {TempTakeActionNotifications.length > 0 ? <ClipboardNotificationSvg /> : <ClipboardSvg />}
      </div>
      <MuiMenu
        id="take-action-menu-content"
        aria-labelledby="take-action-icon-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="take-action-card">
          <div role="button" onClick={handleClose} className="take-action-inner-button">
            {TempTakeActionNotifications.length > 0 ? <ClipboardNotificationSvg /> : <ClipboardSvg />}
          </div>
          <div className="take-action-card-content-container">
            <CardHeaderStyled className="take-action-card-header">
              <CardHeaderButton
                className="take-action-header-button"
                onClick={() => setIsUpdates(false)}
                sx={
                  !isUpdates
                    ? {
                        color: LINK_BLUE,
                        fill: LINK_BLUE,
                      }
                    : {
                        color: GRAY,
                        fill: GRAY,
                      }
                }
              >
                {TempTakeActionNotifications.length > 0 ? <ClipboardNotificationSvg /> : <ClipboardSvg />}
                {bundle.getMessage('action_header_button')}
              </CardHeaderButton>
              <CardHeaderButton
                className="updates-header-button"
                onClick={() => setIsUpdates(true)}
                sx={
                  isUpdates
                    ? {
                        color: LINK_BLUE,
                        fill: LINK_BLUE,
                      }
                    : {
                        color: GRAY,
                        fill: GRAY,
                      }
                }
              >
                <UpdateSvg />
                {bundle.getMessage('updates_header_button')}
              </CardHeaderButton>
            </CardHeaderStyled>
            {!isUpdates ? (
              <div className="todo-list-container">
                {TempSortedList.length == 0 ? (
                  <EmptyTodo>{bundle.getMessage('no_action_items')}</EmptyTodo>
                ) : (
                  TempSortedList.map((item, index) => {
                    const bgColor = (index + 1) % 2 == 0 ? 'even' : 'odd';
                    return (
                      <div key={item.id} className={`todo-list-item-container ${bgColor}`}>
                        <TodoItemLink to={PROJECT_DETAILS.href} onClick={handleClose}>
                          <div className="todo-list-item">
                            <div style={{ width: '30px' }}>{index + 1}.</div>
                            <div className="todo-list-content">
                              <p>{item.message}</p>
                              <p className="todo-item-metadata">
                                {bundle.formatMessage('todo_item', {
                                  tier: item.tier,
                                  vendorName: item.vendorName,
                                  tptaNum: item.tptaNum,
                                })}
                              </p>
                            </div>
                          </div>
                        </TodoItemLink>
                      </div>
                    );
                  })
                )}
              </div>
            ) : (
              <div className="updates-content-container">
                <div className="inner-updates-content-container">
                  {TempNotifications.map((item: UpdateItemProps, index) => {
                    const avatarName =
                      item.entity != null && `${item.entity.split(' ')[0][0]}${item.entity.split(' ')[1][0]}`;
                    const fullName = item.entity != null && item.entity;
                    const bgColor = (index + 1) % 2 == 0 ? 'odd' : 'even';

                    return (
                      <div key={item.id} className={`take-action-update-container ${bgColor}`}>
                        <UpdateItemLink to={PROJECT_DETAILS.href} onClick={handleClose}>
                          <div className="update-item-container">
                            <SmallCircle />
                            <div className="update-inner-content-container">
                              <Avatar>{avatarName}</Avatar>
                              <LargeFont>{fullName}</LargeFont>
                              <SmallFont>
                                {bundle.formatMessage('updates_date_time', { dateOpened: item.timestamp })}
                              </SmallFont>
                              <Break />
                              <UpdatePayload>{item.payload}</UpdatePayload>
                            </div>
                          </div>
                        </UpdateItemLink>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </MuiMenu>
    </div>
  );
}

export default withBundle('components.TodoList')(TodoList);
