import React from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import FederatedSignIn from '../../components/Login/FederatedSignIn';

export default function MyProfilePage() {
  const { user } = useAuth();
  return user ? (
    <div>
      <p>My Profile Page</p>
      <p>
        <Link to="/">Return Home</Link>
      </p>
    </div>
  ) : (
    <FederatedSignIn />
  );
}
