/**
 *
 * These constants serve to have a consistent place for all the STATIC
 * links and breadcrumb links live. See the following page for the sitemap
 * and more info on each tier of pages:
 *
 * https://quip-amazon.com/pd18A2VLbcTV/CX-Project-Sitemap
 *
 * All of the files label element is part of a PUFF-J identifier.
 * PLEASE BE MINDFUL WHEN CHANGING THINGS.
 */

export interface LinkType {
  label: string;
  href: string;
  isDynamic?: boolean;
}

// Home page & Logout
export const LOGOUT: LinkType = { label: 'logout', href: '/login' }; // Change this href once we can actually logout
export const LOGIN: LinkType = { label: 'login', href: '/login' };
export const HOME_PAGE: LinkType = { label: 'home_page', href: '/' };
export const DASHBOARD: LinkType = { label: 'dashboard', href: '/' };
export const DEFAULT_BREADCRUMBS: LinkType[] = [HOME_PAGE, DASHBOARD];

// First tier pages
export const START_INTAKE: LinkType = { label: 'start_intake', href: '/new-intake' };
export const ISSUES_PAGE: LinkType = { label: 'issues', href: '/issues' };
export const TASKS_PAGE: LinkType = { label: 'tasks', href: '/tasks' };
export const CONSULTS_PAGE: LinkType = { label: 'consults', href: '/consults' };
export const SURVEYS_PAGE: LinkType = { label: 'surveys', href: '/surveys' };
export const COMPLETE_ASSESSMENTS_PAGE: LinkType = { label: 'complete_assessments', href: '/complete-assessments' };
export const SEARCH_RESULTS: LinkType = { label: 'search_results', href: '/search' };
export const TPS_CATALOG: LinkType = { label: 'tps_catalog', href: '/catalog' };
export const MY_PROFILE: LinkType = { label: 'my_profile', href: '/profile' };
export const MANAGE_TEAM: LinkType = { label: 'manage_team', href: '/manage-team' };
export const CHANGE_PASSWORD: LinkType = { label: 'change_password', href: '/change-password' };

// Second tier pages
export const NEW_CONSULT: LinkType = { label: 'new_consult', href: '/request-consult' };
export const VIEW_ACTIVITY: LinkType = { label: 'view_activity', href: '/activity' };

// Third tier pages
export const VIEW_DDQ: LinkType = { label: 'ddq', href: '/ddq' };
export const VIEW_INTAKE: LinkType = { label: 'intake_questionnaire', href: '/intake' };
export const PROJECT_DETAILS: LinkType = { label: 'project_details', href: '/project-details' };
export const PROJECT_ISSUES: LinkType = { label: 'project_issues', href: '/project-issues' };
export const PROJECT_ACTIVITY: LinkType = { label: 'project_activity', href: '/project-activity' };
export const CONSULT_DETAILS: LinkType = { label: 'consult_details', href: '/consult-details' };
export const CONSULT_COMMENTS: LinkType = { label: 'consult_comments', href: '/consult-comments' };
export const SURVEY_RESULTS: LinkType = { label: 'survey_results', href: '/survey-results' };
export const SURVEY_PROJECTS: LinkType = { label: 'survey_projects', href: '/survey-projects' };
export const SURVEY_ISSUES_FINDINGS: LinkType = { label: 'survey_issue_findings', href: '/survey-issue-findings' };
export const SURVEY_CONSULTS: LinkType = { label: 'survey_consults', href: '/survey-consults' };
export const COMPLETE_ASSESSMENTS_DETAILS: LinkType = {
  label: 'complete_assessments_details',
  href: '/complete-assessments-details',
};

// Fourth tier pages
export const VIEW_DDQ_NOTES_COMMENTS: LinkType = { label: 'ddq_notes_comments', href: '/notes-comments' };

// Lookup table using K<relative link (string)>, V<Breadcrumb> to very quickly add the breadcrumbs
export const LINKS: Record<string, LinkType> = {
  'project-details': PROJECT_DETAILS,
  'project-issues': PROJECT_ISSUES,
  'project-activity': PROJECT_ACTIVITY,
  'consult-details': CONSULT_DETAILS,
  'consult-comments': CONSULT_COMMENTS,
  'survey-results': SURVEY_RESULTS,
  'survey-projects': SURVEY_PROJECTS,
  'survey-issue-findings': SURVEY_ISSUES_FINDINGS,
  'survey-consults': SURVEY_CONSULTS,
  'complete-assessments-details': COMPLETE_ASSESSMENTS_DETAILS,
  logout: LOGOUT,
  home: HOME_PAGE,
  dashboard: DASHBOARD,
  'new-intake': START_INTAKE,
  issues: ISSUES_PAGE,
  tasks: TASKS_PAGE,
  consults: CONSULTS_PAGE,
  surveys: SURVEYS_PAGE,
  'complete-assessments': COMPLETE_ASSESSMENTS_PAGE,
  search: SEARCH_RESULTS,
  catalog: TPS_CATALOG,
  profile: MY_PROFILE,
  'manage-team': MANAGE_TEAM,
  'change-password': CHANGE_PASSWORD,
  activity: VIEW_ACTIVITY,
  ddq: VIEW_DDQ,
  intake: VIEW_INTAKE,
  'note-comments': VIEW_DDQ_NOTES_COMMENTS,
  'request-consult': NEW_CONSULT,
};
