import React, { createContext, ReactElement, useContext } from 'react';
import { UserMetadata } from '../data/UserMetadata';

const UserContext = createContext<UserMetadata>(undefined);

const useUserContext = () => useContext(UserContext);

interface Props {
  children: ReactElement;
  user?: UserMetadata;
}
function UserProvider(props: Props): ReactElement {
  return <UserContext.Provider value={props.user}>{props.children}</UserContext.Provider>;
}

export { UserProvider, useUserContext };
