export type DefaultFunction = (...a: any[]) => any;
export interface ConsoleType {
  log: DefaultFunction;
  dir: DefaultFunction;
  error: DefaultFunction;
  warn: DefaultFunction;
  info: DefaultFunction;
}
export function getConsole(): ConsoleType {
  const defaultFn = () => undefined;
  if (process.env.NODE_ENV !== 'production') return console;
  return {
    log: defaultFn,
    dir: defaultFn,
    error: defaultFn,
    warn: defaultFn,
    info: defaultFn,
  };
}
