import React, { useState, useReducer, useEffect, useMemo } from 'react';
import axios from 'axios';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { MessageBundle } from '@amzn/arb-tools';
import Assessment, { ProjectState } from '../../../src/data/RecordMetadata';
import Button from '../../components/Button/Button';
import MuiTypography from '@mui/material/Typography';
import MuiCircularProgress from '@mui/material/CircularProgress';
import ThirdPartyTieringAssessmentCard from '../../components/ThirdPartyTieringAssessmentCards/ThirdPartyTieringAssessmentCard';
import NewIntakeCard from '../../components/ThirdPartyTieringAssessmentCards/NewIntakeCard';
import FilterBar from '../../components/FilterBar/FilterBar';
import './LandingPage.css';
import { useQuery } from '@tanstack/react-query';
import { LIGHT_GRAY } from '../../constants/constants';
import { TempAssessmentPayload } from '../../../src/data/TempRecordMetadata';
import filterReducer from '../../components/FilterBar/FilterReducer';
import useAuth from '../../hooks/useAuth';
import FederatedSignIn from '../../components/Login/FederatedSignIn';

function sortFavoritesFirst(projects: Assessment[]): Assessment[] {
  const sorted: Assessment[] = [];
  projects.sort();

  projects.forEach((project) => {
    if (project.isFavorited) sorted.push({ ...project });
  });
  projects.forEach((project) => {
    if (!project.isFavorited) sorted.push({ ...project });
  });

  return sorted;
}

/**
 * This function is simply to reduce repeated code because there is not
 * much variance in the creation of actual swimlanes. However there is some variance
 * in the card types so classname is required.
 *
 * TODO: change cards param to the DTO we plan on creating eventually
 * instead of 'any' type. Same in the inner loop
 *
 * @param laneType
 * @param cards
 * @returns JSX
 */
function createSwimlane(laneType: ProjectState, cards: Assessment[], bundle: MessageBundle) {
  const cardsQuery = useQuery({
    queryKey: ['swimlaneKey'],
    queryFn: () => axios.get('https://jsonplaceholder.typicode.com/todos').then((res) => res.data),
  });
  return (
    <article className={laneType + '-swimlane swimlane-container'}>
      <h3 className="swimlane-container-header">{bundle.formatMessage(laneType, { num: cards.length })}</h3>
      <div className="swimlane-divider"></div>
      {cardsQuery.status == 'success' &&
        cards.map((card: Assessment) => {
          return <ThirdPartyTieringAssessmentCard key={card.tptaNumber} project={card} />;
        })}
      {cardsQuery.status == 'error' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: LIGHT_GRAY,
            minHeight: '100%',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <MuiTypography>Failed to load data. Please try again.</MuiTypography>
          <Button
            // eslint-disable-next-line
            onClick={() => cardsQuery.refetch()}
            type="dark"
            style={{ fontSize: '10px', margin: '10px' }}
          >
            Retry
          </Button>
        </div>
      )}
      {cardsQuery.isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MuiCircularProgress />
        </div>
      )}
      {laneType === ProjectState.INTAKE && <NewIntakeCard />}
    </article>
  );
}

function LandingPage({ bundle }: WithBundleProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [state, dispatch] = useReducer(filterReducer, { componentData: [...TempAssessmentPayload] });
  const { user } = useAuth();
  //memoizing this state properties to avoid unnecessary renders of Filter Child
  const memoizedFilterFields = useMemo(() => state.filterFields, [state.filterFields]);
  const memoizedLabels = useMemo(() => state.labels, [state.labels]);
  const memoizedDispatchFn = useMemo(() => dispatch, [dispatch]);

  //useEffect for initial state to contain filtermap, label sets at component level
  useEffect(() => {
    dispatch({
      type: 'initialSetup',
      payload: {
        labels: ['vendor', 'tier', 'state', 'severity'],
        bundle,
      },
    });
    setLoading(false);
  }, []);

  return user ? (
    <div className="landing-page-content-container">
      <React.Fragment>
        <div className="landing-page-header">
          {loading ? (
            <MuiCircularProgress />
          ) : (
            <FilterBar
              filterFields={memoizedFilterFields}
              filterLabels={memoizedLabels}
              dispatch={memoizedDispatchFn}
              title={'track_my_assessment'}
            />
          )}
        </div>
        <section className="swimlane-container-container">
          {createSwimlane(
            ProjectState.INTAKE,
            sortFavoritesFirst(
              state.componentData.filter(
                (p: any, index: number) =>
                  p.details.member.tieringAssessment.state === ProjectState.INTAKE &&
                  (loading || state.displayData[index]),
              ),
            ),
            bundle,
          )}
          {createSwimlane(
            ProjectState.DDQ,
            sortFavoritesFirst(
              state.componentData.filter(
                (p: any, index: number) =>
                  p.details.member.tieringAssessment.state === ProjectState.DDQ &&
                  (loading || state.displayData[index]),
              ),
            ),
            bundle,
          )}
          {createSwimlane(
            ProjectState.ISSUE,
            sortFavoritesFirst(
              state.componentData.filter(
                (p: any, index: number) =>
                  p.details.member.tieringAssessment.state === ProjectState.ISSUE &&
                  (loading || state.displayData[index]),
              ),
            ),
            bundle,
          )}
        </section>
      </React.Fragment>
    </div>
  ) : (
    <FederatedSignIn />
  );
}

export default withBundle('pages.LandingPage')(LandingPage);
