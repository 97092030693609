import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';

import MuiLink from '@mui/material/Link';
import MuiBox from '@mui/material/Box';
import MuiToolbar from '@mui/material/Toolbar';
import MuiIconButton from '@mui/material/IconButton';
import MuiTypography from '@mui/material/Typography';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';

import { LinkType, MANAGE_TEAM, MY_PROFILE, LOGOUT } from '../../constants/linkConstants';
import logo from '../../images/logo.png';
import ProfileAvatar from './ProfileAvatar';
import './TopBarNav.css';
import { GRAY, PRIMARY_BLUE, SECONDARY_BLUE, WHITE } from '../../constants/constants';

function TopBarNav({ bundle }: WithBundleProps) {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElUser);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings: LinkType[] = [MANAGE_TEAM, MY_PROFILE, LOGOUT];

  // The labels are used within the mounted component to find the proper
  // text from the bundle.
  const navLinks: LinkType[] = [
    { label: 'wiki', href: '/' },
    { label: 'faq', href: '/' },
    { label: 'report_a_bug', href: '/' },
    { label: 'request_a_feature', href: '/' },
  ];

  return (
    <div className="top-navigation-bar-background">
      <MuiToolbar disableGutters>
        {/* Logo and Title */}
        <img className="logo" src={logo} />
        <MuiTypography
          className="header-icon-text-container"
          noWrap
          sx={{
            letterSpacing: 'normal',
            color: WHITE,
            textDecoration: 'none',
            borderLeft: 1,
            fontFamily: 'Amazon Ember',
            fontWeight: 'Regular',
          }}
        >
          <span className="header-icon-text">{bundle.getMessage('header')}</span>
        </MuiTypography>

        {/* Topbar navigation */}
        <MuiBox sx={{ flexGrow: 2, display: 'flex', justifyContent: 'right' }}>
          {navLinks.map((link, index) => (
            <MuiTypography key={index}>
              <a className="nav-element" href={link.href}>
                {bundle.getMessage(link.label)}
              </a>
            </MuiTypography>
          ))}
        </MuiBox>

        <MuiBox sx={{ flexGrow: 0 }}>
          <MuiIconButton
            id="button-app-bar"
            aria-controls={open ? 'menu-app-bar' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenUserMenu}
            sx={{ p: 0 }}
            className="user-menu-dropdown"
          >
            <ProfileAvatar />
          </MuiIconButton>

          <MuiMenu
            MenuListProps={{
              disablePadding: true,
            }}
            PaperProps={{ style: { width: 243, marginLeft: '8px', borderRadius: 0, marginTop: '7px' } }}
            id="menu-app-bar"
            aria-labelledby="button-app-bar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting, index) => (
              <MuiMenuItem
                sx={{
                  height: '45px',
                  paddingLeft: '62px',
                  fontFamily: 'Amazon Ember',
                  fontSize: '16px',
                  borderTop: `0.5px solid ${GRAY}`,
                  backgroundColor: PRIMARY_BLUE,
                  color: WHITE,
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: PRIMARY_BLUE,
                    color: SECONDARY_BLUE,
                  },
                }}
                key={index}
                className="topbar-dropdown-menu-item"
                onClick={handleCloseUserMenu}
              >
                <MuiLink {...{ component: Link, to: setting.href }} style={{ color: 'inherit' }} underline="none">
                  <div>{bundle.getMessage(setting.label)}</div>
                </MuiLink>
              </MuiMenuItem>
            ))}
          </MuiMenu>
        </MuiBox>
      </MuiToolbar>
    </div>
  );
}

export default withBundle('components.TopBarNav')(TopBarNav);
