import React, { useReducer, createContext, useContext } from 'react';
import Assessment from '../data/RecordMetadata';
import { TempAssessmentPayload } from '../../src/data/TempRecordMetadata';

/* eslint-disable */
type DataActions =
  | { type: 'addFavorite'; payload: string }
  | { type: 'removeFavorite'; payload: string }
  | { type: 'toggleFilter'; payload: string[] }
  | { type: 'search'; payload: string };
/* eslint-enable */

const vendorProjects = TempAssessmentPayload;

export const projectsReducer = (projects: Assessment[], action: DataActions): Assessment[] => {
  switch (action.type) {
    case 'addFavorite':
      return projects.map((project) => {
        if (project.tptaNumber === action.payload) project.isFavorited = true;
        return project;
      });
    case 'removeFavorite':
      return projects.map((project) => {
        if (project.tptaNumber === action.payload) project.isFavorited = false;
        return project;
      });
    case 'search':
      const searchResults: Assessment[] = vendorProjects.filter(
        (project) =>
          project.vendorName.toLowerCase().includes(action.payload.toLowerCase()) ||
          project.projectName.toLowerCase().includes(action.payload.toLowerCase()) ||
          project.tptaNumber.toLowerCase().includes(action.payload.toLowerCase()),
      );
      return searchResults;
  }
  return projects;
};

const DataContext = createContext<[Assessment[], React.Dispatch<DataActions>]>([
  TempAssessmentPayload,
  () => {}, // eslint-disable-line
]);

const useDataContext = () => useContext(DataContext);

const DataProvider: React.FC = ({ children }) => {
  const [projects, dispatch] = useReducer(projectsReducer, TempAssessmentPayload);
  return <DataContext.Provider value={[projects, dispatch]}>{children}</DataContext.Provider>;
};

export { DataProvider, useDataContext };
