var endpointMap = {
	en: {
		"components.Breadcrumbs": "translation-en.json",
		"components.FilterBar": "translation-en.json",
		"components.InfoBanner": "translation-en.json",
		"components.NewIntakeCard": "translation-en.json",
		"components.PortalDescriptionAccordion": "translation-en.json",
		"components.ProfileAvatar": "translation-en.json",
		"components.SideBar": "translation-en.json",
		"components.TabNav": "translation-en.json",
		"components.ThirdPartyTieringAssessmentCard": "translation-en.json",
		"components.TodoList": "translation-en.json",
		"components.TopBarNav": "translation-en.json",
		"pages.CompleteAssessmentsPage": "translation-en.json",
		"pages.ConsultsPage": "translation-en.json",
		"pages.LandingPage": "translation-en.json"
	},
	"en-US": {
		"components.Breadcrumbs": "translation-en.json",
		"components.FilterBar": "translation-en.json",
		"components.InfoBanner": "translation-en.json",
		"components.NewIntakeCard": "translation-en.json",
		"components.PortalDescriptionAccordion": "translation-en.json",
		"components.ProfileAvatar": "translation-en.json",
		"components.SideBar": "translation-en.json",
		"components.TabNav": "translation-en.json",
		"components.ThirdPartyTieringAssessmentCard": "translation-en.json",
		"components.TodoList": "translation-en.json",
		"components.TopBarNav": "translation-en.json",
		"pages.CompleteAssessmentsPage": "translation-en.json",
		"pages.ConsultsPage": "translation-en.json",
		"pages.LandingPage": "translation-en.json"
	},
	und: {
		"components.Breadcrumbs": "translation.json",
		"components.FilterBar": "translation.json",
		"components.InfoBanner": "translation.json",
		"components.NewIntakeCard": "translation.json",
		"components.PortalDescriptionAccordion": "translation.json",
		"components.ProfileAvatar": "translation.json",
		"components.SideBar": "translation.json",
		"components.TabNav": "translation.json",
		"components.ThirdPartyTieringAssessmentCard": "translation.json",
		"components.TodoList": "translation.json",
		"components.TopBarNav": "translation.json",
		"pages.CompleteAssessmentsPage": "translation.json",
		"pages.ConsultsPage": "translation.json",
		"pages.LandingPage": "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
