/**
 * Constants used for declaring the default localization.
 */
import { LocalizationContextBuilder } from '@amzn/arb-tools';

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_LC_BUILDER = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

/**
 * Using MUI you will sometimes need to override the styles directly in the
 * code and not through CSS. This is because a lot of MUI styles override our
 * CSS file's styling.
 */

// Base colors
export const BLUE_BLACK = '#0e212d';
export const DARK_GRAY = '#666';
export const GRAY = '#c4c4c4';
export const LIGHT_GRAY = '#f4f4f4';
export const OFF_BLACK = '#2c3042';
export const WHITE = '#fff';
export const BLACK = '#000000';

// Primary
export const PRIMARY_BLUE = '#132f52';
export const SECONDARY_BLUE = '#335669';
export const TERTIARY_BLUE = '#6e90a3';

// Secondary
export const ASSESSOR_ORANGE = '#e49b57';
export const DARK_ORANGE = '#bc3e00';
export const FADED_RED = '#ef9494';
export const LINK_BLUE = '#126de0';
export const MINT_GREEN = '#1f8476';
export const RED = '#960404';
export const REQUESTOR_GREEN = '#71d599';
export const VENDOR_PURPLE = '#a2a9ed';
export const YELLOW = '#e6be2c';

//Side bar width, height
export const SIDEBAR_WIDTH = '264px';
export const TOP_NAV_HEIGHT = '108px';

//Font Family for the font style
export const AMAZON_EMBER = 'Amazon Ember';

//Filter bar textTransform for muibuttons
export const TEXT_TRANS = 'none';
