import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { getConsole } from '../utils/fns';

interface AuthContextType {
  error?: any;
  loading: boolean;
  user?: any;
}

let count = 0;

const console = getConsole();

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const [user, setUser] = useState<any>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((_user) => {
        setUser(_user);
        setLoading(false);
      })
      .catch((_error) => {
        if (!count) {
          count++;
          setLoading(true);
        } else {
          if (process.env.NODE_ENV === 'development') console.error(_error);
          setError(_error);
        }
      });
  }, []);

  //TODO we can add different auth flows based on requirement
  useEffect(() => {
    Hub.listen('auth', async (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          setUser(data.payload.data);
          break;
        case 'signUp':
          break;
        case 'signOut':
          setUser(undefined);
          break;
        //TODO
        case 'signIn_failure':
          setUser(undefined);
          setLoading(false);
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          setUser(undefined);
          break;
        case 'configured':
          break;
        case 'customOAuthState':
          break;
        default:
      }
    });
  }, []);

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
    }),
    [user, loading, error],
  );

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
