import React from 'react';
import { getTPSPortalUrl } from '../../utils/redirectionUtils';
import useAuth from '../../hooks/useAuth';
import FederatedSignIn from '../../components/Login/FederatedSignIn';

export default function ProjectLandingPage() {
  const { user } = useAuth();

  return user ? (
    <div style={{ height: '100%', width: '100%' }}>
      <p>Temp Project Landing Page</p>
      <a href={getTPSPortalUrl(window.location.search, window.location.pathname)}>Click Here</a>
      <p>Temp Project Landing Page</p>
    </div>
  ) : (
    <FederatedSignIn />
  );
}
