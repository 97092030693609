import React from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiLink from '@mui/material/Link';
import MuiBox from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';
import MuiListSubheader from '@mui/material/ListSubheader';
import MuiDrawer from '@mui/material/Drawer';
import MuiStarBorderIcon from '@mui/icons-material/StarBorder';
import MuiInputBase from '@mui/material/InputBase';
import MuiSearchIcon from '@mui/icons-material/Search';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemText from '@mui/material/ListItemText';
import MuiArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Badge, CircularProgress } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import FavoritesStar from '../FavoritesStar/FavoritesStar';
import CatalogueIcon from '../../images/catalogue.svg';
import {
  SIDEBAR_WIDTH,
  TOP_NAV_HEIGHT,
  WHITE,
  PRIMARY_BLUE,
  SECONDARY_BLUE,
  TERTIARY_BLUE,
  RED,
  AMAZON_EMBER,
} from '../../constants/constants';

import {
  TPS_CATALOG,
  DASHBOARD,
  ISSUES_PAGE,
  CONSULTS_PAGE,
  SURVEYS_PAGE,
  COMPLETE_ASSESSMENTS_PAGE,
  TASKS_PAGE,
} from '../../constants/linkConstants';
import './SideBar.css';
import { useDataContext } from '../../context/DataProvider';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import Button from '../Button/Button';

const MuiSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(2),
  width: '80%',
}));

const MuiSearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MuiStyledInputBase = styled(MuiInputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function SideBar({ bundle }: WithBundleProps) {
  const [projects] = useDataContext();
  const favorites = projects.filter((project) => project.isFavorited);

  const [open, setOpen] = React.useState(true);
  const [searchplaceholder, setPlaceholder] = React.useState(bundle.getMessage('search_placeholder'));
  const handleFocus = () => {
    setPlaceholder('');
  };

  const [selectedIndex, setSelectedIndex] = React.useState(Number);

  React.useEffect(() => {
    const activeIndex = sessionStorage.getItem('activeIndex');
    if (activeIndex === null) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(Number(activeIndex));
    }
  }, []);

  const handleListItemClick = (index: string) => {
    setSelectedIndex(Number(index));
    // sessionStorage.setItem('activeIndex', index);
  };

  const navItems = [
    { name: DASHBOARD.label, link: DASHBOARD.href, count: 0 },
    { name: ISSUES_PAGE.label, link: ISSUES_PAGE.href, count: 1 },
    { name: TASKS_PAGE.label, link: TASKS_PAGE.href, count: 1 },
    { name: CONSULTS_PAGE.label, link: CONSULTS_PAGE.href, count: 0 },
    { name: SURVEYS_PAGE.label, link: SURVEYS_PAGE.href, count: 0 },
    { name: COMPLETE_ASSESSMENTS_PAGE.label, link: COMPLETE_ASSESSMENTS_PAGE.href, count: 0 },
  ];

  const favQuery = useQuery({
    queryKey: ['favKey'],
    queryFn: () => axios.get('https://jsonplaceholder.typicode.com/todos').then((res) => res.data),
  });

  return (
    <MuiBox sx={{ display: 'flex', overflowY: 'none' }}>
      <MuiDrawer
        variant="permanent"
        sx={{
          width: '100%',
          flexShrink: 0,
          overflowY: 'none',
          [`& .MuiDrawer-paper`]: {
            width: SIDEBAR_WIDTH,
            top: TOP_NAV_HEIGHT,
            boxSizing: 'content-box',
            backgroundColor: PRIMARY_BLUE,
            color: WHITE,
          },
        }}
      >
        <MuiList
          sx={{
            paddingTop: '37px',
            paddingLeft: '8px',
          }}
        >
          {navItems.map((navItem, index) => (
            <MuiListItem key={navItem.name} disablePadding>
              <MuiListItemButton
                {...{ component: Link, to: navItem.link }}
                selected={selectedIndex === index}
                disableTouchRipple
                onClick={() => handleListItemClick(index.toString())}
                sx={{
                  transition: 'none',
                  height: '46px',
                  marginRight: '65px',
                  '&.Mui-selected': {
                    backgroundColor: SECONDARY_BLUE,
                    color: WHITE,
                    marginRight: '65px',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: SECONDARY_BLUE,
                      marginRight: '65px',
                    },
                    '.MuiListItemText-primary': {
                      color: WHITE,
                      borderRadius: '10px',
                    },
                  },
                }}
              >
                <MuiListItemText
                  primary={bundle.getMessage(navItem.name)}
                  primaryTypographyProps={{
                    align: 'left',
                    fontSize: '20px',
                    fontFamily: AMAZON_EMBER,
                    lineHeight: '22px',
                  }}
                  sx={{
                    color: TERTIARY_BLUE,
                    '&:hover': {
                      color: WHITE,
                      backgroundColor: 'transparent',
                    },
                  }}
                />
              </MuiListItemButton>

              {/* Indicator Notification icon */}
              <Badge
                color="primary"
                sx={{
                  '& .MuiBadge-colorPrimary': {
                    backgroundColor: RED,
                    marginRight: '40px',
                  },
                }}
                badgeContent={navItem.name === 'Issues' ? navItem.count : 0}
              ></Badge>
            </MuiListItem>
          ))}
        </MuiList>

        {/* For the Search Bar. */}
        <MuiSearch
          sx={{
            marginTop: '62px',
            backgroundColor: '#0A2C3F',
            color: TERTIARY_BLUE,
            paddingLeft: 0,
            borderRadius: '10px',
            width: '240px',
          }}
        >
          <MuiSearchIconWrapper sx={{ paddingLeft: '5px' }}>
            <MuiSearchIcon
              sx={{
                fontSize: '35px',
                fontWeight: 300,
                paddingRight: 0,
              }}
            />
          </MuiSearchIconWrapper>
          <MuiStyledInputBase
            id="input-placeholder"
            placeholder={searchplaceholder}
            inputProps={{ 'aria-label': 'search' }}
            onFocus={handleFocus}
            className="sidebar-searchbar"
            sx={{
              fontSize: '22px',
              fontFamily: AMAZON_EMBER,
              fontWeight: 'bold',
              paddingTop: '0px',
            }}
          />
        </MuiSearch>

        {/* For Favorites and TPS Catalog section. */}
        <MuiList
          disablePadding
          aria-labelledby="nested-list-subheader"
          sx={{
            height: '100px',
            backgroundColor: PRIMARY_BLUE,
          }}
          subheader={
            <MuiListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{
                bgcolor: 'inherit',
                color: WHITE,
              }}
            >
              <MuiListItemButton
                disableTouchRipple
                onClick={() => setOpen(!open)}
                sx={{
                  right: '30px',
                  px: 3,
                  pt: 2.5,
                  pb: open ? 0 : 0.5,
                  bgcolor: 'inherit',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <MuiStarBorderIcon sx={{ marginTop: '54px', color: TERTIARY_BLUE }} />
                <MuiListItemText
                  primary={bundle.getMessage('favorites')}
                  primaryTypographyProps={{
                    fontSize: '20px',
                    fontWeight: 'medium',
                    lineHeight: '24px',
                    fontFamily: AMAZON_EMBER,
                    marginTop: '54px',
                    marginLeft: '7px',
                  }}
                  sx={{
                    color: TERTIARY_BLUE,
                    '&:hover': {
                      color: WHITE,
                    },
                  }}
                />
                {/* Collapsible icon for viewing the favorites content*/}
                <MuiArrowDropDownIcon
                  sx={{
                    mr: -1,
                    color: TERTIARY_BLUE,
                    marginTop: '58px',
                    transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                    transition: '0.1s',
                    fontSize: '2rem',
                    marginRight: '-49px',
                  }}
                />
              </MuiListItemButton>
            </MuiListSubheader>
          }
        >
          <div
            style={{
              overflowY: open ? 'auto' : 'hidden',
              height: '150px',
              marginRight: '55px',
              scrollbarWidth: 'thin',
              display: open ? 'block' : 'none',
            }}
          >
            {open &&
              favQuery.status == 'success' &&
              favorites.map((favorite, index: number) => (
                <MuiListItemButton key={index} sx={{ padding: 1 }}>
                  <div className="favorites-list">
                    <FavoritesStar project={favorite} style={{ marginTop: '10px' }} />
                    <MuiLink
                      {...{ component: Link, to: favorite.tptaNumber }}
                      color="#6390a3"
                      underline="none"
                      sx={{
                        '&:hover': {
                          color: WHITE,
                        },
                      }}
                    >
                      <MuiListItemText
                        primary={favorite.vendorName}
                        primaryTypographyProps={{
                          fontSize: '20px',
                          fontFamily: AMAZON_EMBER,
                          marginLeft: '2px',
                        }}
                      />
                    </MuiLink>
                  </div>
                </MuiListItemButton>
              ))}
            {favQuery.status == 'error' && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  marginLeft: '20px',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <MuiTypography align="center">Failed to load data. Please try again.</MuiTypography>
                <Button
                  // eslint-disable-next-line
                  onClick={() => favQuery.refetch()}
                  type="dark"
                  style={{ fontSize: '10px', margin: '5px', marginBottom: 0 }}
                >
                  Retry
                </Button>
              </div>
            )}
            {favQuery.isLoading && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '60px' }}>
                <CircularProgress />
              </div>
            )}
          </div>

          <div className="tps-catalog">
            <MuiListItemButton
              {...{ component: Link, to: TPS_CATALOG.href }}
              sx={{
                fontSize: '20px',
                fontWeight: 'medium',
                lineHeight: '24px',
                fontFamily: AMAZON_EMBER,
                color: TERTIARY_BLUE,
                '&:hover': {
                  color: WHITE,
                },
              }}
            >
              <img
                src={CatalogueIcon}
                style={{ height: '18px', width: '18px', paddingRight: '10px', color: TERTIARY_BLUE }}
              />
              {bundle.getMessage(TPS_CATALOG.label)}
            </MuiListItemButton>
          </div>
        </MuiList>
      </MuiDrawer>
    </MuiBox>
  );
}

export default withBundle('components.SideBar')(SideBar);
