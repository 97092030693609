export const BETA_URL = 'https://beta-pdx.assessments.security.amazon.dev';
export const GAMMA_URL = 'https://gamma-pdx.assessments.security.amazon.dev';
export const PROD_URL = 'https://prod-pdx.assessments.security.amazon.dev';

export const DEV_IRM_URL = 'https://dev.sn.irm.a2z.com';
export const UAT_IRM_URL = 'https://uat.sn.irm.a2z.com';
export const PROD_IRM_URL = 'https://irm.a2z.com';

//different portals of IRM users
export const TPS = 'tps';
export const SVDP = 'svdp';

//query string parameters of different table view
export const TPTA_TABLE = new Map([
  ['view', 'sp'],
  ['id', 'tps_vta'],
  ['table', 'sn_vdr_risk_asmt_vdr_tiering_assessment'],
]);
export const TPRI_TABLE = new Map([
  ['view', 'sp'],
  ['id', 'tps_vri'],
  ['table', 'sn_vdr_risk_asmt_issue'],
]);
export const CONSULT_TABLE = new Map([
  ['view', 'sp'],
  ['id', 'tps_vrt_consults'],
  ['table', 'sn_vdr_risk_asmt_task'],
]);
export const VENDOR_INVITE_CONTACT = new Map([
  ['view', 'sp'],
  ['id', 'svdp_profile_edit'],
  ['table', 'vm_vdr_contact'],
]);
export const VENDOR_PROFILE_EDIT = new Map([
  ['view', 'sp'],
  ['id', 'svdp_profile_edit'],
]);
export const VENDOR_PROFILE = new Map([['id', 'svdp_profile']]);
export const DDQ = new Map([['id', 'svdp_questions']]);
export const VENDOR_TEAM_MANAGEMENT = new Map([['id', 'svdp_team']]);
