import React from 'react';
import MuiTypography from '@mui/material/Typography';
import MuiSearchIcon from '@mui/icons-material/Search';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiTextField from '@mui/material/TextField';
import MuiCheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MuiCheckBoxIcon from '@mui/icons-material/CheckBox';
import MuiBox from '@mui/material/Box';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiPaper from '@mui/material/Paper';
import MuiInputBase from '@mui/material/InputBase';
import MuiIconButton from '@mui/material/IconButton';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { AMAZON_EMBER, BLACK, LINK_BLUE, PRIMARY_BLUE } from '../../constants/constants';
import './FilterBar.css';

export interface FilterFields {
  filterMap: Map<string, string[]>;
  countMap: Map<string, number>;
}

export type FieldLabel = 'vendor' | 'tier' | 'state' | 'severity';

interface FilterBarBase extends WithBundleProps {
  title: string;
  filterFields: FilterFields;
  filterLabels: FieldLabel[];
  dispatch: any;
}

type FilterBarProps = FilterBarBase;

const icon = <MuiCheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <MuiCheckBoxIcon fontSize="small" />;

const closeBtnStyle = {
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
    color: BLACK,
    visibility: 'visible',
  },
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator:hover': {
    color: LINK_BLUE,
  },
};

function FilterBar({ bundle, ...props }: FilterBarProps): React.ReactElement {
  const filterRef = React.useRef(new Map()); //constant reference for filter fields to avoid unnecessary child renders due to filter state changes

  return (
    <React.Fragment>
      <MuiBox sx={{ width: '100%', display: 'flex', height: '32px' }}>
        <MuiBox sx={{ width: 'auto' }}>
          <MuiTypography variant="h6" sx={{ fontFamily: AMAZON_EMBER, color: PRIMARY_BLUE, width: 'auto' }}>
            {bundle.getMessage(props.title)}
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ width: 'auto', mx: '1%' }}>
          <MuiTypography
            variant="h6"
            sx={{ fontFamily: AMAZON_EMBER, color: BLACK, width: 'auto', fontSize: '12px', pt: '15%' }}
          >
            {bundle.getMessage('filter_by')}
          </MuiTypography>
        </MuiBox>
        <MuiBox sx={{ display: 'flex', width: '60%', height: '32px' }}>
          {props.filterLabels.map((fieldLabel) => {
            const options: string[] = props.filterFields.filterMap.get(fieldLabel) || [];
            return (
              <MuiAutocomplete
                multiple
                id={fieldLabel}
                key={fieldLabel}
                options={[...options]}
                disableCloseOnSelect
                getOptionLabel={(option: string) => option}
                onChange={(event: any, value: Array<string>, reason: string) => {
                  const currentValue =
                    event.type === 'keydown' ? event.target.getAttribute('aria-activedescendant').split('-') : [];

                  props.dispatch({
                    type: reason,
                    payload: {
                      type: fieldLabel,
                      option:
                        currentValue.length > 0
                          ? options[currentValue[2]]
                          : event.currentTarget.childNodes[1].nodeValue,
                    },
                  });

                  filterRef.current.set(fieldLabel, [value[value.length - 1], value.length]);
                }}
                renderOption={(renderprops, option, { selected }) => (
                  <li {...renderprops}>
                    <MuiCheckbox icon={icon} checkedIcon={checkedIcon} sx={{ ml: '-15px' }} checked={selected} />
                    {option +
                      (fieldLabel != 'vendor'
                        ? ` ${bundle.getMessage('open_bracket')}${props.filterFields.countMap.get(
                            option,
                          )}${bundle.getMessage('close_bracket')}`
                        : '')}
                  </li>
                )}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={
                      filterRef.current.get(fieldLabel) && filterRef.current.get(fieldLabel)[1] > 0
                        ? bundle.formatMessage('filter_label', {
                            filterLabel: fieldLabel,
                            number: filterRef.current.get(fieldLabel)[1],
                          })
                        : bundle.formatMessage('filter_label_empty', { filterLabel: fieldLabel })
                    }
                    placeholder={
                      filterRef.current.get(fieldLabel) && filterRef.current.get(fieldLabel)[0]
                        ? filterRef.current.get(fieldLabel)[0]
                        : options[0]
                    }
                    InputLabelProps={{
                      style: {
                        color:
                          filterRef.current.get(fieldLabel) && filterRef.current.get(fieldLabel)[1] > 0
                            ? LINK_BLUE
                            : undefined,
                        height: '1.4375em',
                      },
                    }}
                    sx={closeBtnStyle}
                  />
                )}
                sx={{
                  mr: '10px',
                  width: fieldLabel == 'vendor' || fieldLabel == 'state' ? '25%' : '20%',
                  height: '1.4375em',
                }}
                size={'small'}
                renderTags={() => {
                  return null;
                }}
                autoHighlight
                fullWidth={true}
              />
            );
          })}
        </MuiBox>
        <MuiPaper component="form" sx={{ display: 'flex', alignItems: 'center', width: '20%', borderRadius: '30px' }}>
          <MuiIconButton type="button" sx={{ p: '10px' }} aria-label={`${bundle.getMessage('aria_label_search')}`}>
            <MuiSearchIcon />
          </MuiIconButton>
          <MuiInputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={bundle.getMessage('search_placeholder')}
            inputProps={{ 'aria-label': `${bundle.getMessage('aria_label_search')}` }}
          />
        </MuiPaper>
      </MuiBox>
    </React.Fragment>
  );
}

export default React.memo(withBundle('components.FilterBar')(FilterBar));
