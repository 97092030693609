import React, { CSSProperties, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import { StarSvg } from './StarSvg';
import { useDataContext } from '../../context/DataProvider';
import Assessment from '../../../src/data/RecordMetadata';
import './FavoritesStar.css';

const StarButton = styled('button')({
  padding: 0,
  border: 'none',
  background: 'none',
  '&:hover': {
    background: 'none',
    cursor: 'pointer',
  },
});

function getFavoriteState(projects: Assessment[], project: Assessment) {
  for (let i = 0; i < projects.length; i++) {
    if (projects[i].tptaNumber === project.tptaNumber && projects[i].isFavorited) return true;
  }

  return false;
}

interface Props {
  project: Assessment;
  style?: CSSProperties;
}

export default function FavoritesStar(props: Props) {
  const [projects, dispatch] = useDataContext();
  const [isFavorited, setIsFavorited] = useState(getFavoriteState(projects, props.project));

  useEffect(() => {
    setIsFavorited(getFavoriteState(projects, props.project));
  }, [projects]);

  const className = isFavorited ? 'favorite-icon favorited' : 'favorite-icon';

  const handleClick = () => {
    if (isFavorited) {
      dispatch({ type: 'removeFavorite', payload: props.project.tptaNumber });
      setIsFavorited(false);
    } else {
      dispatch({ type: 'addFavorite', payload: props.project.tptaNumber });
      setIsFavorited(true);
    }
  };

  return (
    <StarButton className={className} onClick={handleClick} style={props.style}>
      <StarSvg />
    </StarButton>
  );
}
