type FilterActions = 'selectOption' | 'removeOption' | 'clear' | 'reset';

/* eslint-disable */
type DataActions =
  | { type: 'initialSetup'; payload: { labels: string[]; bundle: any } }
  | { type: FilterActions; payload: { type: string; option: string | null } };
/* eslint-enable */

function propertyValueGetter(label: string, data: any, bundle: any): string {
  switch (label) {
    case 'vendor':
      return data.vendorName;
    case 'tier':
      return bundle.getMessage(data.details.member.tieringAssessment.thirdpartyTier);
    case 'state':
      return bundle.getMessage(data.state);
    case 'severity':
      return bundle.getMessage(data.severity);
  }

  return '';
}

function fieldsMapper(filterMap: any, countMap: any, visited: any, field: string, fieldValue: string) {
  if (filterMap.has(field)) {
    if (!visited.has(fieldValue)) {
      filterMap.get(field)?.push(fieldValue);
      field !== 'vendor' && countMap.set(fieldValue, 1);
      visited.add(fieldValue);
    } else {
      field !== 'vendor' && countMap.set(fieldValue, (countMap.get(fieldValue) || 0) + 1);
    }
  } else {
    filterMap.set(field, [fieldValue]);
    field !== 'vendor' && countMap.set(fieldValue, 1);
    visited.add(fieldValue);
  }
}

//fields in state is very dynamic depending on which component using it so intentionally kept it as any
function filterReducer(state: any, action: DataActions): any {
  let str = '';
  let type = '';

  if (action.type != 'initialSetup') {
    type = action.payload.type;
    str = action.payload.option || '';
    const index = str.indexOf('(');
    str = index != -1 ? str.slice(0, index - 1) : str;
  }

  switch (action.type) {
    case 'initialSetup': {
      const filterMap = new Map<string, string[]>(); //to keep track of filter fields
      const countMap = new Map<string, number>(); //to keep track of count in case of repeated
      const visited = new Set(); //set to check across to avoid repitations

      state.filterFields = { filterMap: filterMap, countMap: countMap };
      state.labels = action.payload.labels;
      state.displayData = new Array<boolean>();
      state.filtersApplied = new Set<string>();
      state.bundle = action.payload.bundle;

      for (const data of state.componentData) {
        state.displayData.push(true);
        for (const label of state.labels) {
          if (state[label] == undefined) state[label] = new Set<string>();
          fieldsMapper(filterMap, countMap, visited, label, propertyValueGetter(label, data, state.bundle));
        }
      }
      break;
    }
    case 'selectOption': {
      state[type].add(str);
      state.filtersApplied.add(type);
      break;
    }
    case 'removeOption': {
      state[type].delete(str);
      if (state[type].size == 0) {
        state.filtersApplied.delete(type);
      }
      break;
    }
    case 'clear': {
      state[type].clear();
      state.filtersApplied.delete(type);
      break;
    }

    //aaron don't need over all clearing filter component but keeping this as backup for user experience
    case 'reset': {
      state.labels.map((label: string) => {
        state[label].clear();
      });
      state.displayData.fill(true);
      state.filtersApplied.clear();
      return { ...state };
    }
  }

  //have to change data type from any to one of that components data type later before finalizing everything
  state.componentData.map((data: any, index: number) => {
    let display = true;

    for (const filter of state.filtersApplied) {
      if (state[filter]) {
        if (!state[filter].has(propertyValueGetter(filter, data, state.bundle))) {
          display = false;
        }
      }
    }
    state.displayData[index] = display;
  });

  return { ...state };
}
export default filterReducer;
