import { KatSpinner } from '@amzn/katal-react';
import React from 'react';

import AppInitWrapper from './AppInitWrapper';

interface PropsType {
  children: JSX.Element;
}

// Wrap component in application context.
const AppWrapper: React.FC<PropsType> = (props: PropsType) => (
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<KatSpinner size="large" />}>{props.children}</React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>
);

export default AppWrapper;
