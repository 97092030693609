import axios from 'axios';
import React from 'react';
import MuiCard from '@mui/material/Card';
import MuiTypography from '@mui/material/Typography';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { useQuery } from '@tanstack/react-query';
import Button from '../Button/Button';
import MuiCircularProgress from '@mui/material/CircularProgress';
import { LIGHT_GRAY } from '../../constants/constants';
import './InfoBanner.css';
import { InfoBannerTempNotifications } from '../../data/TempRecordMetadata';

function InfoBanner({ bundle }: WithBundleProps) {
  const infoQuery = useQuery({
    queryKey: ['infoKey'],
    queryFn: () => axios.get('https://jsonplaceholder.typicode.com/todos').then((res) => res.data),
  });

  return (
    <div className="info-banner-container">
      <div className="info-banner-background">
        <div className="info-banner-header">{bundle.getMessage('take_action')}</div>

        <div>
          {/* If data fetching is successful */}
          {infoQuery.status == 'success' &&
            InfoBannerTempNotifications.map((notification) => (
              <MuiCard key={notification.id} className="info-banner-card">
                <div className="info-banner-card-content">
                  {bundle.formatMessage(notification.notificationText, {
                    projectName: notification.projectName,
                    // keys are hardcoded to make React happy
                    // eslint-disable-next-line
                    span: (children: any) => <span key="1">{children}</span>,
                    i: (chunk: string) => <i key="2">{chunk}</i>,
                  })}
                </div>
              </MuiCard>
            ))}

          {/* If an error occurred during data fetching */}
          {infoQuery.status == 'error' && (
            <div
              className="retry-button"
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: LIGHT_GRAY,
                height: '100%',
                marginLeft: '20px',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <MuiTypography align="center">Failed to load data. Please try again.</MuiTypography>
              {/* Retry button to refetch the data */}
              <Button
                // eslint-disable-next-line
                onClick={() => infoQuery.refetch()}
                type="dark"
                style={{ fontSize: '10px', margin: '5px' }}
              >
                Retry
              </Button>
            </div>
          )}

          {/* If data is still loading */}
          {infoQuery.isLoading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <MuiCircularProgress />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withBundle('components.InfoBanner')(InfoBanner);
